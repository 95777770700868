import React from "react";

import dapp from "../Assests/images/banner/dapp/dapp-kit.png"
import dapp1 from "../Assests/images/banner/dapp/1.jpg"
import dapp2 from "../Assests/images/banner/dapp/2.jpg"
import dapp3 from "../Assests/images/banner/dapp/3.jpg"
import DChainStarterKit from "../Assests/D-Chain Starter Kit.rar"



class App extends React.Component {


  render() {
    return (
      <div className="App">



        <section className=" internal-page-padding-top pb-5">
          <div className="container d-chain-pos-banner-section">
            {/* <p className="banner-text-2 text-start">D-CHAIN</p> */}
            <div class="row">
              <div class="col">
                <main>
                  <aside class="sidebar-left">
                    <ol className="p-0 m-0 mb-5">
                      <li>
                        <nav id="navbar-example3" class="h-100 flex-column align-items-stretch">
                          <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-2" href="#item-1-1">About Us</a>
                            {/* <a class="nav-link ms-2" href="#item-1-2">Create your DAPP</a>
                            <a class="nav-link ms-2" href="#item-1-3">Truffle</a> */}
                          </nav>
                        </nav>
                      </li>


                    </ol>
                  </aside>
                  <section className="d-chain-scroll-container">
                    <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" class="scrollspy-example-2" tabindex="0">
                      <div id="item-1-1">
                        <p>D-Ecosystem DAO is a decentralized autonomous organization, a kind of bottom-up entity structure with no central authority. Members of D-Ecosystem DAO own tokens of the D-Ecosystem DAO and members can vote on initiatives for the D-Ecosystem DAO. Smart contracts are and will be implemented for the D-Ecosystem DAO, and the code governing the operation of the D-Ecosystem DAO will be publicly announced.</p>
                        <p>
                        DCX is the native token of D-Ecosystem, serving as a key element in the decentralized governance structure of the organization. As a DCX holder, individuals have the power to participate in decision-making processes, ensuring a collective and inclusive approach to shaping the future of D-Ecosystem and its DeFi protocol.
                        </p>

                        <p>DCX holders are responsible for governing the D-Ecosystem Protocol and improving governance itself. Anyone can be an DCX holder, and all are welcome to come join us in creating the future of DeFi.</p>
                     
                     <p>D-Ecosystem is deeply committed to creating a sustainable future. It reflects this commitment through its innovative technologies and community-driven approach. By collaborating with like-minded individuals and organizations, D-Ecosystem aims to achieve a harmonious coexistence of technology and the environment, meeting the needs of present and future generations. </p>
                      
                      <p>D-Ecosystem reduces carbon emissions through its Proof-of-Stake (PoS) consensus mechanism, which consumes less energy than traditional Proof-of-Work (PoW) systems. </p>

                      <p>It also promotes sustainable practices and waste reduction using blockchain in supply chain management. D-Ecosystem's sustainability efforts are backed by evidence, including a significant reduction in its carbon footprint through the PoS system. </p>
                      </div>




                    </div>


                  </section>

                </main>
              </div>
            </div>
          </div>
        </section>





      </div>
    );
  }
}

export default App;
