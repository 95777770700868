import React from 'react'

import dapp from '../Assests/images/banner/dapp/dapp-kit.png'
import dapp1 from '../Assests/images/banner/dapp/1.jpg'
import dapp2 from '../Assests/images/banner/dapp/2.jpg'
import dapp3 from '../Assests/images/banner/dapp/3.jpg'
import DChainStarterKit from '../Assests/D-Chain Starter Kit.rar'

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <section className=" internal-page-padding-top pb-5">
          <div className="container d-chain-pos-banner-section">
            {/* <p className="banner-text-2 text-start">D-CHAIN</p> */}
            <div class="row">
              <div class="col">
                <h1 className="text-center fw-bold mb-5">
                  Terms and Conditions
                </h1>
                <main>
                  <aside class="sidebar-left">
                    <ol className="p-0 m-0 mb-5">
                      <li>
                        <nav
                          id="navbar-example3"
                          class="h-100 flex-column align-items-stretch"
                        >
                          <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-2" href="#item-1-1">
                              Terms of Use
                            </a>
                          </nav>
                        </nav>
                      </li>
                    </ol>
                  </aside>
                  <section className="d-chain-scroll-container">
                    <div
                      data-bs-spy="scroll"
                      data-bs-target="#navbar-example3"
                      data-bs-smooth-scroll="true"
                      class="scrollspy-example-2"
                      tabindex="0"
                    >
                      <div id="item-1-1">
                        <p className="my-2">
                          These terms (“Terms”) govern the use of services
                          (“Services”) provided by D-ecosystem DAO and all
                          platforms affiliated with D-ecosystem DAO
                          (“D-ecosystem”, “we”, “us”, “our”). These terms are
                          binding on the users of D-ecosystem and its Platforms
                          (“you”, “your”, “user(s)”) which includes, but is not
                          limited to, any person or entity who accesses, browses
                          or uses in any form the Services provided by us.
                        </p>
                        <p className="my-3">
                          By accessing the Services provided by our Platforms,
                          you expressly consent to the Terms and Conditions
                          contained herein and understand its meaning and
                          intent. Please read these Terms and Conditions and our
                          Privacy Policy carefully before using our Platforms or
                          the Services provided by us. All transactions are
                          final, and there shall be no refunds or cancellations,
                          except as may be required by applicable law or
                          regulation or unless expressly agreed upon by us in
                          writing.
                        </p>

                        <h4 className='my-2'>GENERAL</h4>

                        <ol>
                          <li className='my-2'>
                            You agree and acknowledge that you are solely
                            responsible for your use of the Platforms or
                            Services and that we are not liable for any harm,
                            loss, or damages that may result from your use of
                            the Services. This includes, but is not limited to,
                            any damages caused by incorrect use of the Services,
                            such as faulty transactions, network and technical
                            failures, unauthorized access to your wallets, legal
                            and regulatory issues and consequences, or fraud by
                            third parties
                          </li>
                          <li className='my-2'>
                            You agree and acknowledge that you will use the
                            Platforms or Services in compliance with all
                            applicable laws and regulations and that you will
                            not use the Services for any unlawful or prohibited
                            purposes. This includes, but is not limited to,
                            using the Services
                            <ul className="sub-content">
                              <li className='my-2'>to fund terrorism or other
                            criminal activity; </li>
                            <li className='my-2'>to evade any export
                            restrictions or economic sanctions;</li>
                            <li className='my-2'>to conduct
                            illegal money transmission, currency exchange, or
                            money laundering; or </li>
                            <li className='my-2'>if you are restricted or
                            prohibited by any applicable laws and regulations
                            from using the Services. We reserve the right to
                            terminate your access to the Services at any time if
                            we determine that you are in breach of this
                            provision.</li>
                              </ul>
                          </li>
                        </ol>

                        <p className='my-2'>
                          Due to regulatory restrictions, the Services provided by D-ecosystem and its Platforms are not available to residents or citizens of the United States of America or any of its territories. If you are located in or a citizen of the United
                          States of America, you are prohibited from accessing,
                          browsing or using our Services in any form. By using
                          our Services, you represent and warrant that you are
                          not located in or a citizen of the United States of
                          America or any of its territories. We reserve the
                          right to terminate your access to our Services at any
                          time if we determine that you are in violation of this
                          provision.
                        </p>

                        <h4 className='my-2'>RISKS ASSOCIATED WITH USE SERVICES</h4>
                        <ol>
                          <li className='my-2'>
                            You acknowledge and agree that there are certain
                            risks involved in using the Services and purchasing,
                            owning and using DCX-Coin for the receipt of
                            Services. These risks include, but are not limited
                            to, technical failures, network congestion, legal
                            and regulatory uncertainties, market volatility,
                            fraud and theft.
                          </li>
                          <li className='my-2'>
                            By purchasing DCX-Coin, you confirm that you have
                            read and understood these Terms and that you are
                            willing to assume these risks. You also represent
                            and warrant that you are legally capable and
                            authorized to purchase DCX-Coin and to use the
                            Services in your jurisdiction.
                          </li>
                          <li className='my-2'>
                            You are solely responsible for securing your wallet,
                            vault or other storage mechanisms that you use to
                            receive and hold DCX-Coin purchased, as well as any
                            private key(s) or other credentials required to
                            access such storage mechanism(s). If you lose your
                            private key(s) or other access credentials, you may
                            lose access to your DCX-Coin and we will not be
                            liable for any such loss or any costs, expenses or
                            damages arising from it.
                          </li>
                          <li className='my-2'>
                            You must make the payment for purchasing DCX-Coin to
                            the multi-signature address specified by us. If you
                            send the payment to any other address or entity that
                            is not specified by us, you acknowledge and agree
                            that we will not receive such payment and we will
                            have no obligation to deliver any DCX-Coin to you.
                            We will also have no obligations of any kind to you
                            regarding such payment.
                          </li>
                          <li className='my-2'>
                            We will only deliver DCX-Coin to you after we have
                            received your payment in full and the amount quoted
                            for your purchase request, i.e., the Purchase Price,
                            has been fully paid. We will consider the Purchase
                            Price to be paid in full as soon as we receive three
                            network confirmations of the transaction. We reserve
                            the right to declare your purchase request void and
                            refuse or cancel your payment at any time at our
                            sole and absolute discretion.
                          </li>
                          <li className='my-2'>
                            We reserve the right to modify any timelines
                            described herein, at our sole and absolute
                            discretion, to account for network congestion or
                            other technical challenges that may affect the
                            delivery of DCX-Coin or the use of the Services.
                          </li>
                          <li className='my-2'>
                            We will not be responsible for any amount received
                            from an incompatible wallet or Smart Contract
                            account that results in “lost” DCX-Coin that cannot
                            be withdrawn from the account. You must ensure that
                            you use a compatible wallet or Smart Contract
                            account to receive and hold DCX-Coin purchased from
                            us.
                          </li>
                          <li className='my-2'>
                            All purchases of DCX-Coin from us are final and
                            non-refundable, except as may be required by
                            applicable law or regulation or unless expressly
                            agreed upon by us in writing. You bear all risks
                            related to any refund of payment made during the
                            DCX-Coin sale and we will not be liable for any such
                            refund or any costs, expenses or damages arising
                            from it.
                          </li>
                          <li className='my-2'>
                            You acknowledge and agree that any amount paid for
                            DCX-Coin is exclusive of all applicable taxes. You
                            are solely responsible for determining what taxes
                            apply to your purchase of DCX-Coin, including but
                            not limited to value added tax, sales tax, use tax
                            or any other similar tax, and for withholding,
                            collecting, reporting and remitting the correct
                            taxes to the appropriate tax authorities. We will
                            not be responsible for withholding, collecting,
                            reporting or remitting any sales, use, value added
                            or similar tax arising from your purchase of
                            DCX-Coin.
                          </li>
                          <li className='my-2'>
                            You acknowledge and agree that purchasing DCX-Coin
                            does not grant you any rights or ownership over the
                            Services or the Platforms provided by us. You also
                            acknowledge and agree that DCX-Coin is not a
                            security, commodity, currency or any other form of
                            financial instrument and does not represent any
                            share, stake or equity in us or our Platforms.
                          </li>
                          <li className='my-2'>
                            You acknowledge and agree that the value of DCX-Coin
                            may fluctuate significantly over time due to various
                            factors beyond our control, such as supply and
                            demand, market conditions, regulatory changes,
                            technical developments, hacking attacks or other
                            malicious activities. We do not guarantee or warrant
                            the stability or liquidity of DCX-Coin and we will
                            not be liable for any loss of value or inability to
                            sell or exchange DCX-Coin.
                          </li>
                          <li className='my-2'>
                            You acknowledge and agree that using the Services
                            may involve interacting with third-party platforms,
                            protocols, applications or services that are not
                            owned or controlled by us (“Third-Party Services”).
                            We do not endorse or assume any responsibility for
                            such Third-Party Services and we will not be liable
                            for any harm, loss or damages arising from your use
                            of such Third-Party Services. You are solely
                            responsible for reviewing the terms and conditions
                            and privacy policies of such Third-Party Services
                            before using them.
                          </li>
                        </ol>

                        <h4 className='my-2'>DENIAL OF ACCESS AND TERMINATION</h4>

                        <p className="my-2">
                          We reserve the right to terminate the use of our
                          Platforms and Services and restrict you from such use
                          if you are found to be in breach of these Terms.
                        </p>

                        <p className='my-2'>
                          In case of termination, we shall not liable for any
                          loss of information, damage or any other loss of
                          similar nature.
                        </p>

                        <h4 className='my-2'>USER CODE OF CONDUCT</h4>
                        <h5 className="my-2">
                          Obligations and Prohibited Actions:
                        </h5>

                        <ul>
                          <li className='my-2'>
                            You shall not use any material that is false,
                            offensive, harmful, obscene or pornographic,
                            hateful, defamatory, libelous, derogatory, abusive,
                            threatening, or in any other way illegal or
                            infringing upon the legal rights of others, or in
                            violation of this clause.
                          </li>
                          <li className='my-2'>
                            You shall be civil and respectful of other Users and
                            shall not display toxic, trolling or behaviors that
                            lead to harassment of any kind, including but not
                            exclusively mental or emotional.
                          </li>
                          <li className='my-2'>
                            You shall not impersonate any other natural or legal
                            person, and use their identification or contact
                            details, create accounts in their name, or falsely
                            state or misrepresent any association or affiliation
                            with such persons.
                          </li>
                          <li className='my-2'>
                            You shall not interfere with security-related
                            features of the Platforms, including but not limited
                            to:
                            <ul>
                              <li>disabling or circumventing features that prevent or limit use or copying of any content; or</li>
                              <li>reverse engineering or otherwise attempting to
                            discover the source code of any portion of the
                            Platforms except to the extent that the activity is
                            expressly permitted by applicable law. Any attempt
                            at such interference shall attract a ban from the
                            Platforms.</li>
                            </ul> 
                          </li>
                          <li className='my-2'>
                            You shall not collect, generate, affect in any way
                            usernames or email addresses using bots or any other
                            methods or sell or transfer the User profile to any
                            person or entity without our written permission.
                          </li>
                          <li className='my-2'>
                            Spamming which shall include but is not restricted
                            to excessive use of capital letters; repetition of
                            text, and exclamation points on the site interferes
                            with the use of service and is prohibited.
                          </li>
                          <li className='my-2'>
                            You shall not use DCX-Coin for any illegal or
                            unauthorized purposes, such as funding terrorism or
                            other criminal activities, evading sanctions or
                            taxes, laundering money, or engaging in unlawful
                            gambling or speculation.
                          </li>
                          <li className='my-2'>
                            You shall not use D-Ecosystem DAO Platforms or
                            Services in a way that harms, disrupts, interferes
                            with, or violates the rights of other Users, third
                            parties, or us. This includes, but is not limited
                            to:
                            <ul>
                              <li className='my-2'>spreading malware, viruses, spyware, or
                            other harmful software; </li>
                            <li className='my-2'>sending unsolicited
                            messages, advertisements, or spam; </li>
                            <li className='my-2'>engaging in
                            fraudulent activities or scams; </li>
                            <li className='my-2'>infringing on
                            the intellectual property rights of others;</li>
                            <li className='my-2'>posting abusive, hateful, defamatory, libelous,
                            derogatory, threatening.</li>
                              </ul> 
                            
                          </li>
                        </ul>
                        <h4 className='my-2'>ISSUANCE AND USE OF DCX-COIN</h4>
                        <p className="my-3">
                          The purpose of the D-Ecosystem's tokens (“DCX-Coin”)
                          is to be used in a blockchain platform developed by us
                          for the rendering of the Services. The Services
                          include, but are not limited to, enabling
                          decentralized governance, voting, and participation in
                          the D-Ecosystem Network and its affiliated platforms
                          and applications.
                        </p>
                        <p className="my-3">
                          The purchase, ownership, receipt or possession of
                          DCX-Coin carries no rights, express or implied, other
                          than the right to use DCX-Coin as a means to enable
                          usage of and interaction with the D-Ecosystem Network,
                          if successfully completed and deployed. You understand
                          and accept that DCX-Coin do not represent or confer
                          any ownership right or stake, share, security, or
                          equivalent rights, or any right to receive future
                          revenue shares, intellectual property rights or any
                          other form of participation in or relating to us, the
                          Platforms or any other affiliates of D-Ecosystem
                          and/or us and/or any of our corporate affiliates. The
                          only rights that DCX-Coin carry is those relating to
                          the provision and receipt of Services, subject to
                          limitations and conditions of these Terms.
                        </p>
                        <p className="my-3">
                          The DCX-Coin are not and are not intended to be a
                          digital currency, security, commodity or any kind of
                          financial instrument. The information provided is not
                          intended to form the basis for any investment decision
                          and no specific recommendations. You should conduct
                          your own research and analysis before purchasing
                          DCX-Coin and seek independent professional advice if
                          necessary. We shall not be responsible for any direct
                          or consequential loss or damage of any kind whatsoever
                          arising directly or indirectly from 
                          <ul className='sub-content'>
                            <li className='my-2'>reliance on
                          any information provided,</li>
                          <li className='my-2'>any error, omission or
                          inaccuracy in any such information; or</li>
                          <li className='my-2'>any
                          action resulting from such information.</li>
                            </ul>
                        </p>
                        <p className="my-3">
                          By purchasing, owning, and using DCX-Coin, you
                          expressly acknowledge and assume the risks concerning
                          losing access to tokens due to loss of Private Key(s),
                          custodial error or purchaser error and risks related
                          to the protocol; Risk of mining attacks; risk of
                          hacking and security weaknesses; risks associated with
                          markets for tokens; risk of uninsured losses; risks
                          associated with uncertain regulations and enforcement
                          actions; risks arising from taxation; risk of
                          competing platforms; risk of insufficient interest in
                          the platform or distributed applications; risks
                          associated with the development and maintenance of the
                          Platforms;; risk of dissolution of the D-Ecosystem or
                          Platform; risks arising from lack of governance rights
                          and any other such unanticipated risks. You also
                          acknowledge and agree that we do not guarantee or
                          warrant the availability, functionality, performance,
                          security, reliability, or quality of the D-Ecosystem
                          Network or its affiliated platforms and applications.
                          We reserve the right to migrate the DCX-Coin to
                          another protocol in the future, in our reasonable
                          discretion, for the operation of the Platform and
                          Services.
                        </p>

                        <h4 className='my-2'>THIRD-PARTY SERVICES </h4>
                        <p className="my-3">
                          We may use third-party sites, services or resources
                          and make no representation or warranties of any kind
                          regarding any site, service, or resource provided by a
                          third party, including, but not limited to, any
                          representations or warranties regarding its accuracy,
                          completeness, usefulness, security, or legitimacy.
                        </p>
                        <p className="my-3">
                          We have no control over third-party sites, services,
                          and resources, and we are not responsible for any
                          harm, loss, or damage that may arise from your use of
                          such services. If you decide to access any of the
                          third-party sites, services, or resources, you do so
                          entirely at your own risk and subject to the terms and
                          conditions for such sites, services, or resources.
                        </p>

                        <h4 className='my-2'>REPRESENTATIONS AND WARRANTIES</h4>
                        <ol>
                          <li className='my-2'>
                            You represent and warrant that you have read and
                            understood these terms and conditions and you
                            acknowledge and agree that there are risks
                            associated with the services and acquisition of
                            DCX-Coin, owning DCX-Coin and using DCX-Coin for the
                            provision or receipt of services in the ecosystem
                            including (but not necessarily limited to) the risks
                            described above;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you have a sufficient
                            understanding of technical and business matters
                            (including those that relate to the Services)
                            cryptographic tokens, token storage mechanisms (such
                            as token wallets), and blockchain technology to
                            understand these Terms and to appreciate the risks
                            and implications of purchasing the DCX-Coin;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you understand the
                            restrictions and risks associated with the creation
                            of DCX-Coin as set forth herein, and acknowledges
                            and assumes all such risks;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you have obtained
                            sufficient information about the DCX-Coin, the
                            Services and the Platforms to make an informed
                            decision to purchase the DCX-Coin;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you understand that
                            the DCX-Coin confer only the right to provide and
                            receive Services and do not confer other rights of
                            any form with respect to our Platforms, including,
                            but not limited to, any ownership, distribution,
                            redemption, liquidation, proprietary (including all
                            forms of intellectual property), or other financial
                            or legal rights;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you are purchasing
                            DCX-Coin solely for the purpose of receiving
                            Services, participating in our project, and
                            supporting the development, testing, deployment and
                            operation of D-Ecosystem;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you are aware of the
                            commercial risks associated with our project, and
                            you are not purchasing DCX-Coin for any other
                            purposes, including, but not limited to, any
                            investment, speculative or financial purpose;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that your purchase of
                            DCX-Coin complies with applicable laws and
                            regulations in your jurisdiction, including, but not
                            limited to:
                            <ul className='sub-content'>
                              <li className='my-2'>legal capacity and any other
                            threshold requirements in your jurisdiction for the
                            purchase of the DCX-Coin and entering into contracts
                            with us; </li>
                              <li className='my-2'>any foreign exchange or regulatory
                            restrictions applicable to such purchase; and </li>
                              <li className='my-2'>any governmental or other consents that may need to
                            be obtained;</li>
                              </ul> 
                           
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you will comply with
                            any applicable tax obligations in your jurisdiction
                            arising from your purchase of DCX-Coin;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that, if you are
                            purchasing DCX-Coin on behalf of any entity, you are
                            authorized to accept these Terms on such entity’s
                            behalf and that such entity will be responsible for
                            breach of these Terms by you or any other employee
                            or agent of such entity;
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you are not resident,
                            domiciled, or purchasing DCX-Coin from a
                            jurisdiction that prevents the acquisition of
                            DCX-Coin.
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you have obtained
                            independent legal advice with respect to same before
                            accepting these Terms, and, in accepting these
                            Terms.
                          </li>
                          <li className='my-2'>
                            You represent and warrant that you are not a citizen
                            or resident of a geographic area in which access to
                            or use of the Services or the acceptance of delivery
                            of the DCX-Coin is prohibited by applicable law,
                            decree, regulation, treaty or administrative act,
                            and if your country of residence or other
                            circumstances change such that the above
                            representations are no longer accurate, you will
                            immediately cease using the Services;
                          </li>
                          <li className='my-2'>
                            If you are registering to use the Services on behalf
                            of a legal entity, you further represent and warrant
                            that:
                            <ul className="sub-content">
                              <li className='my-2'>
                                such legal entity is duly organized and validly
                                existing under the applicable laws of the
                                jurisdiction of its organization; and
                              </li>
                              <li className='my-2'>
                                you are duly authorized by such legal entity to
                                act on its behalf.
                              </li>
                            </ul>
                            <p className="my-2">
                              You also represent and warrant that none of the
                              funds being used to purchase the Tokens are to
                              your knowledge proceeds obtained or derived
                              directly or indirectly as a result of illegal
                              activities, and the funds being used to purchase
                              the Tokens which will be advanced by you under
                              these Terms will not represent proceeds of crime
                              as per the anti-money laundering and countering
                              the financing of terrorism (AML/CFT) and you
                              acknowledge that we may in the future be required
                              by law to disclose your name and other information
                              relating to these Terms, on a confidential basis,
                              pursuant to the (AML/CFT), and to the best of your
                              knowledge, none of the funds to be provided by you
                              are being tendered on behalf of a person or entity
                              who has not been identified to you, and you will
                              promptly notify us if you discover that any of
                              such representations cease to be true, and will
                              promptly provide us with all necessary information
                              in connection therewith.
                            </p>
                            
                          </li>
                          <li className="my-2">
                            You represent and warrant that you understand and
                            agree that D-Ecosystem DAO is a decentralized
                            autonomous organization that operates on the basis
                            of smart contracts and blockchain technology, and
                            that you will not hold us or any of our affiliates
                            liable for any actions, decisions, or outcomes of
                            D-Ecosystem DAO that may affect your use of the
                            Services or the value of the Tokens.
                          </li>
                        </ol>

                        <h4 className='my-2'>INDEMNIFICATION</h4>
                        <ol>
                          <li className='my-2'>
                            To the fullest extent permitted by applicable law,
                            you will indemnify, defend and hold harmless
                            D-Ecosystem and its respective past, present and
                            future employees, officers, directors, contractors,
                            consultants, equity holders, suppliers, vendors,
                            service providers, parent companies, subsidiaries,
                            affiliates, agents, representatives, predecessors,
                            successors and assigns (the “Company Parties”) from
                            and against all claims, demands, actions, damages,
                            losses, costs and expenses (including attorneys’
                            fees) that arise from or relate to:
                            <ul className="sub-content">
                              <li className='my-2'>your purchase or use of DCX-Coin;</li>
                              <li>
                                your responsibilities or obligations under these
                                Terms;
                              </li>
                              <li className='my-2'>your violation of any of these Terms; or</li>
                              <li className='my-2'>
                                your violation of any rights of any other person
                                or entity. We reserve the right to exercise sole
                                control over the defence, at your expense, of
                                any claim subject to indemnification under this
                                Section. This indemnity is in addition to, and
                                not in lieu of, any other indemnities set forth
                                in any written agreement with you.
                              </li>
                            </ul>
                          </li>
                          <p className="my-2">
                            You also agree to cooperate fully with us in the
                            defence of any claim that is the subject of your
                            obligations under this Section. You will not settle
                            any claim without our prior written consent.
                          </p>
                        </ol>
                        <h4 className='my-2'>DISCLAIMERS</h4>
                        <ol>
                          <li className='my-2'>
                          You represent and warrant and covenant that you have read and understood these terms and conditions to the fullest extent permitted by applicable law and except as otherwise specified in writing by us, the dcx-coin are sold on an “as is” and “as available” basis without warranties of any kind, and d-ecosystem dao expressly disclaims all implied warranties as to the dcx-coin, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement; we do not represent or warrant that the dcx-coin are reliable, current or error-free, meet your requirements, or that defects in the dcx-coin will be corrected; and we 
                          <p className='my-2'>(iii) d-ecosystem dao can not and does not represent or warrant that the dcx-coin or the delivery mechanism for dcx-coin are free of viruses or other harmful components.</p>
                          
                          </li>
                          <li className='my-2'>
                          These terms do not constitute a prospectus, an offer document of any sort, or are intended to constitute an offer of securities or a solicitation for investment in securities in any jurisdiction. If you decide to participate and purchase dcx-coin, please note that your contribution does not represent, constitute or involve the exchange of cryptocurrencies for any form of securities, investment units and/or form of ordinary shares in the project, on any platform, in d-ecosystem dao, or any other related company.
                          </li>
                          <li className='my-2'>
                          The dcx-coin are not intended to constitute securities and/or collective investment units in any project, platform or company. The dcx-coin holder does not receive any form of dividend or other revenue rights, nor does it represent any participation in a profit-sharing scheme. These terms and conditions in any part thereof and any copy thereof must not be transmitted to any country where distribution or dissemination of these documents and its information is prohibited or restricted. No regulatory authority has examined or approved to this date of any of the information set out in this document. The publication, distribution or dissemination of these terms do not imply the applicable laws.
                          </li>
                          <li className='my-2'>
                          Regulatory requirements or rules have been complied with to the maximum extent permitted by the applicable laws, regulations and rules, d-ecosystem dao, its founders, team members and any third party involved in the project shall not be liable for any direct, indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any acceptance of or reliance on these terms, or any part thereof and/or information provided on the web page <a className='DChainStarterKit' href='https://www.d-ecosystem.io/'>https://d-ecosystem.Io </a> <a className='DChainStarterKit' href="https://d-ecosystem.io/d-swap.io"> /d-swap.Io</a><a className='DChainStarterKit' href="https://d-ecosystem.io/d-nft.io"> /d-nft.Io</a><a className='DChainStarterKit' href="https://d-ecosystem.io/d-wallet.io"> /d-wallet.Io/</a> (and not listed platforms on here).
                          </li>
                          <li className="my-2">
                          All statements contained in these terms, webpage, statements made in press releases or in any place accessible by the public and oral statements that may be made by d-ecosystem dao, its founders, team members and any third party involved in the project and acting on behalf of d-ecosystem dao, that are not  statements of historical fact constitute “forward-looking statements”. All statements regarding the company.
                          </li>
                          <li className="my-2">
                          Neither d-ecosystem dao, its founders, team members, any third party involved in the project nor any other person represents, warrants and/or undertakes that the actual future results, performance or achievements of d-ecosystem dao will be as discussed in these “forward-looking statements.” no information in these terms should be considered to be business, legal, financial or advice regarding contribution or participation to the development of the d-ecosystem network and any of its projects. D-ecosystem dao does not make or intends to make, and hereby disclaims, any representation, warranty or undertaking in any form whatsoever to any entity or person, including any representation, warranty or undertaking in relation to the truth, accuracy, and completeness of any of the information set out in these terms. The information contained on these terms and conditions are of descriptive nature only. You should not rely on them as a basis for making any decisions or actions regarding the purchase or use of dcx-coin or the services. You should conduct your own due diligence and seek independent professional advice before purchasing or using dcx-coin or the services.
                          </li>
                        </ol>
                        <h4 className='my-2'>RELEASE</h4>
                        <p className="my-2">
                          To the fullest extent permitted by applicable law, you
                          release us and our Company Parties from
                          responsibility, liability, claims, demands and/or
                          damages (actual and consequential) of every kind and
                          nature, known and unknown (including, but not limited
                          to, claims of negligence), arising out of or related
                          to disputes between users and the acts or omissions of
                          third parties. You expressly waive any rights you may
                          have under any other statute or common law principles
                          that would otherwise limit the coverage of this
                          release to include only those claims that you may know
                          or suspect to exist in your favour at the time of
                          agreeing to this release.
                        </p>

                        <h4 className='my-2'>LIMITATION OF LIABILITY</h4>
                        <ol>
                          <li className="my-2">
                            To the fullest extent permitted by applicable law,
                            in no event we or any of the E-ecosystem DAO Parties
                            shall be liable for any indirect, special,
                            incidental, consequential, or exemplary damages of
                            any kind (including, but not limited to, where
                            related to loss of revenue, income or profits, loss
                            of use or data, or damages for business
                            interruption) arising out of or in any way related
                            to the sale or use of the DCX Coins or otherwise
                            related to these terms, regardless of the form of
                            action, whether based in contract, tort (including,
                            but not limited to, simple negligence, whether
                            active, passive or imputed), or any other legal or
                            equitable theory (even if the party has been advised
                            regarding the possibility of such damages and
                            regardless of whether such damages were
                            foreseeable).
                          </li>
                          <li className="my-2">
                            In no event will the joint aggregate liability of
                            the E-ecosystem DAO Parties and us, whether in
                            contract, warranty, tort (including negligence,
                            whether active, passive or imputed), or other
                            theory, arising out of or relating to these terms or
                            the use of or inability to use the DCX Coins, exceed
                            the amount you paid to E-ecosystem DAO for the DCX
                            Coins. These limitations are essential to enable us
                            to offer the DCX Coins at a reasonable price and to
                            protect our interests. You acknowledge and agree
                            that you have read and understood these limitations
                            and that they are fair and reasonable.
                          </li>
                        </ol>

                        <h4 className='my-2'>GOVERNING LAW AND DISPUTE RESOLUTION</h4>
                        <p className="my-2">
                          Any claims, disputes and controversies arising out of
                          these Terms or in connection with the access or use of
                          D-Ecosystem DAO Court shall be settled in the
                          D-Ecosystem DAO Court following the dispute process
                          established herein.
                        </p>
                        <p className="my-2">
                          Notwithstanding the above, if a party wished to bring
                          such a claim, dispute or controversy in court, it
                          shall do so in the Court of the city of Victoria
                          (Republic of Seychelles) and these Terms shall be
                          governed by and construed and enforced in accordance
                          with the laws of Republic of Seychelles. However, we
                          retain the right to bring any suit, action or
                          proceeding against You, in your country of residence
                          or any other relevant country. You agree to waive any
                          and all objections to the exercise of jurisdiction
                          over you by such courts and to venue in such courts.
                        </p>
                        <p className="my-2">
                          Waiver of Class Action - You waive off any/all right
                          of class action lawsuit, class-wide arbitration and/or
                          in any other proceeding in which either party acts or
                          proposes to act in a representative capacity shall
                          only bring claims in an individual capacity through
                          individual arbitration.
                        </p>
                        <p className="my-2">
                          Any cause arising out of these Terms shall commence
                          within one (1) year after the cause of action accrues.
                          Otherwise, such cause of action is permanently barred.
                        </p>
                        <p className="my-2">
                          By using D-Ecosystem DAO Court, you agree to abide by
                          its rules and procedures and to respect its decisions.
                          You also acknowledge that D-Ecosystem DAO Court is an
                          experimental platform that may have technical issues
                          or errors and that you use it at your own risk.
                        </p>

                        <h4 className='my-2'>SEVERABILITY</h4>
                        <p className="my-2">
                          If any term, clause or provision of these Terms is
                          held unlawful, void or unenforceable, then that term,
                          clause or provision will be severable from these Terms
                          and will not affect the validity or enforceability of
                          any remaining part of that term, clause or provision,
                          or any other term, clause or provision of these Terms.
                        </p>
                        <p className="my-2">
                          I can help you rewrite the text as you requested. Here
                          is the rewritten text:
                        </p>

                        <h4 className='my-2'>MISCELLANEOUS</h4>
                        <ol>
                          <li className="my-2">
                            Entire Agreement- These Terms (and the documents
                            referred to herein) constitute the entire agreement
                            relating to your purchase of DCX Coins. We may
                            assign rights and obligations under these Terms. Our
                            failure to exercise or enforce any right or
                            provision of these Terms will not operate as a
                            waiver of such right or provision.
                          </li>
                          <li className="my-2">
                            Rights Reserved - We reserve the right to modify,
                            update and/or change the Terms contained herein from
                            time to time. Such changes shall be reflected in our
                            Terms and Conditions, and continuing usage shall
                            imply acceptance of the new Terms.
                          </li>
                          <li className="my-2">
                            We shall not be liable for any delay or failure to
                            perform any obligation under these Terms where the
                            delay or failure results from any cause beyond
                            reasonable control.
                          </li>
                          <li className="my-2">
                            Purchasing DCX Coins from us does not create any
                            form of partnership, joint venture, or any other
                            similar relationship between you and us. Except as
                            otherwise provided herein, these Terms are intended
                            solely for the benefit of you and are not intended
                            to confer third-party beneficiary rights upon any
                            other person or entity.
                          </li>
                          <li className="my-2">You agree and acknowledge that all agreements, notices, disclosures, and other communications that we provide to you, including these Terms, will be provided in electronic form.</li>
                          <li className="my-2">You also agree and acknowledge that D-Ecosystem is a decentralized platform that aims to empower individuals and communities through digital innovation and collaboration. By purchasing DCX Coins, you support the vision and mission of D-Ecosystem and its community.</li>
                        </ol>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default App
