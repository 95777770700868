import React, { useEffect } from 'react';
import jQuery from "jquery";
import $ from "jquery";
import "../../Assests/css/Roadmap.css"
import glow1 from "../../Assests/images/banner/glow/1.png"
import Milestone from "../../Assests/Milestone timeline.pdf"
import Roadmapleft from "../../Assests/images/banner/roadmap-left.png"
import Roadmapright from "../../Assests/images/banner/roadmap-right.png"



export default function Roadmap() {
    useEffect(() => {


        // $('.arrow-tabs-left').click(function(){
        //     var imageToSlide = $('.road-5'); // Get id of image

        //     switch(true) {
        //         case ($('.road-5').hasClass('active')):
        //             console.log("1")
        //             $('.road-5').removeClass('active').addClass('pos2');
        //             break;
        //         case ($('.road-5').hasClass('pos2')):
        //             console.log("2")
        //             $('.road-5').removeClass('pos2').addClass('pos3');
        //             break;
        //         case ($('.road-5').hasClass('pos3')):
        //             console.log("3")
        //             $('.road-5').removeClass('pos3').addClass('pos4');
        //             break;
        //         case ($('.road-5').hasClass('pos4')):
        //             console.log("4")
        //             $('.road-5').removeClass('pos4').addClass('pos1');
        //     }
        // }); 


        $('.road-arrow-5 .arrow-tabs-left').click(function () {
            $('.road-5').removeClass('active');
            $('.road-4').addClass('active');
            $('.map-5').removeClass('active');
            $('.map-4').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').show();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-4 .arrow-tabs-left').click(function () {
            $('.road-4').removeClass('active');
            $('.road-3').addClass('active');
            $('.map-4').removeClass('active');
            $('.map-3').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').show();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-3 .arrow-tabs-left').click(function () {
            $('.road-3').removeClass('active');
            $('.road-2').addClass('active');
            $('.map-3').removeClass('active');
            $('.map-2').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').show();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-2 .arrow-tabs-left').click(function () {
            $('.road-2').removeClass('active');
            $('.road-1').addClass('active');
            $('.map-2').removeClass('active');
            $('.map-1').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').show();
        });
        $('.road-arrow-1 .arrow-tabs-right').click(function () {
            $('.road-1').removeClass('active');
            $('.road-2').addClass('active');
            $('.map-1').removeClass('active');
            $('.map-2').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').show();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-2 .arrow-tabs-right').click(function () {
            $('.road-2').removeClass('active');
            $('.road-3').addClass('active');
            $('.map-2').removeClass('active');
            $('.map-3').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').show();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-3 .arrow-tabs-right').click(function () {
            $('.road-3').removeClass('active');
            $('.road-4').addClass('active');
            $('.map-3').removeClass('active');
            $('.map-4').addClass('active');
            $('.road-arrow-5').hide();
            $('.road-arrow-4').show();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').hide();
        });
        $('.road-arrow-4 .arrow-tabs-right').click(function () {
            $('.road-4').removeClass('active');
            $('.road-5').addClass('active');
            $('.map-4').removeClass('active');
            $('.map-5').addClass('active');
            $('.road-arrow-5').show();
            $('.road-arrow-4').hide();
            $('.road-arrow-3').hide();
            $('.road-arrow-2').hide();
            $('.road-arrow-1').hide();
        });
    }, []);
    $(function () {
        var inputs = $('.input');
        var paras = $('.description-flex-container').find('p');
        inputs.click(function () {
            var t = $(this),
                ind = t.index(),
                matchedPara = paras.eq(ind);

            t.add(matchedPara).addClass('active');
            inputs.not(t).add(paras.not(matchedPara)).removeClass('active');
        });
    });

    return (
        <div className="my-lg-5">
            <div className='road-arrow-1'>
            <div className="d-flex flex-row ">
                <div className="">
                    <img className="roadmap-arrow-tabs arrow-tabs-left" src={Roadmapleft} alt="arrow" />
                </div>
                <div className="ms-auto">
                    <img className="roadmap-arrow-tabs arrow-tabs-right" src={Roadmapright} alt="arrow" />
                </div>
            </div>
            </div>
            <div className='road-arrow-2'>
            <div className="d-flex flex-row">
                <div className="">
                    <img className="roadmap-arrow-tabs arrow-tabs-left" src={Roadmapleft} alt="arrow" />
                </div>
                <div className="ms-auto">
                    <img className="roadmap-arrow-tabs arrow-tabs-right" src={Roadmapright} alt="arrow" />
                </div>
            </div>
            </div>
            <div className='road-arrow-3'>
            <div className="d-flex flex-row">
                <div className="">
                    <img className="roadmap-arrow-tabs arrow-tabs-left" src={Roadmapleft} alt="arrow" />
                </div>
                <div className="ms-auto">
                    <img className="roadmap-arrow-tabs arrow-tabs-right" src={Roadmapright} alt="arrow" />
                </div>
            </div>
            </div>
            <div className='road-arrow-4'>
            <div className="d-flex flex-row">
                <div className="">
                    <img className="roadmap-arrow-tabs arrow-tabs-left" src={Roadmapleft} alt="arrow" />
                </div>
                <div className="ms-auto">
                    <img className="roadmap-arrow-tabs arrow-tabs-right" src={Roadmapright} alt="arrow" />
                </div>
            </div>
            </div>
            <div className='road-arrow-5'>
            <div className="d-flex flex-row">
                <div className="">
                    <img className="roadmap-arrow-tabs arrow-tabs-left" src={Roadmapleft} alt="arrow" />
                </div>
                <div className="ms-auto">
                    <img className="roadmap-arrow-tabs arrow-tabs-right" src={Roadmapright} alt="arrow" />
                </div>
            </div>
            </div>
            <div class="flex-parent">
                <div class="input-flex-container">
                    <div class="input road-1">
                        <span data-info="2019"></span>
                    </div>
                    <div class="input road-2">
                        <span data-info="2020"></span>
                    </div>
                    <div class="input road-3">
                        <span data-info="2021"></span>
                    </div>
                    <div class="input road-4">
                        <span data-info="2022"></span>
                    </div>
                    <div class="input road-5 active">
                        <span data-info="2023"></span>
                    </div>

                </div>
                <div className="position-relative">
                    <img className="glow-effects-circle-image-1" src={glow1} alt="glow" />

                    <div class="description-flex-container">

                        <p className="map-1">
                            <div class="history-tl-container">
                                <h3 className="mb-3">2019</h3>
                                <ul class="tl">
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q1</div>
                                        <div class="item-detail"></div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q2</div>
                                        <div class="item-detail"></div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q3</div>
                                        <div class="item-detail">Ideation to DEvelop Decentralised Ecosystem.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q4</div>
                                        <div class="item-detail">Launch of Tokens and Sales to get early founders.</div>
                                    </li>

                                </ul>
                            </div>
                        </p>
                        <p className="map-2">
                            <div class="history-tl-container">
                                <h3 className="mb-3">2020</h3>
                                <ul class="tl">
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q1</div>
                                        <div class="item-detail">Created final Draft for D-Ecosystem.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q2</div>
                                        <div class="item-detail">Started Research on D-Ecosystem.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q3</div>
                                        <div class="item-detail">Analysing and development of D-Ecosystem .</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q4</div>
                                        <div class="item-detail">Development and research.</div>
                                    </li>
                                </ul>
                            </div>
                        </p>
                        <p className="map-3">

                            <div class="history-tl-container">
                                <h3 className="mb-3">2021</h3>
                                <ul class="tl">
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q1</div>
                                        <div class="item-detail">Analysing smart contract and execution .</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q2</div>
                                        <div class="item-detail">Smart contract research and development.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q3</div>
                                        <div class="item-detail">Started developmnet of D-Wallet , SWAP, DEX, Yield Farming and Blockchain </div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q4</div>
                                        <div class="item-detail">Testing and analysing of smart contract </div>
                                    </li>
                                </ul>
                            </div>
                        </p>
                        <p className="map-4">
                            <div class="history-tl-container">
                                <h3 className="mb-3">2022</h3>
                                <ul class="tl">
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q1</div>
                                        <div class="item-detail">Testing of Extension Android and IoS wallet .</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q2</div>
                                        <div class="item-detail">Launch of Wallets.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q3</div>
                                        <div class="item-detail">Development of D-Chain , IDO and Explorer.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q4</div>
                                        <div class="item-detail">Testing Of D-Chain , Explorer and IDO.</div>
                                    </li>
                                </ul>
                            </div>
                        </p>
                        <p className="map-5 active">
                            <div class="history-tl-container">
                                <h3 className="mb-3">2023</h3>
                                <ul class="tl">
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q1</div>
                                        <div class="item-detail">
                                            Launch of Test net blockchain <br />
                                            Launch of Main net Blockchain <br />
                                            Launch of testnet and mainnet explorer <br />
                                            Launch of Airdrop Module <br />
                                            Launch of Launchpad
                                        </div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q2</div>
                                        <div class="item-detail">Auditing and Launch of SWAP, DEX, Bridge and NFT Marketplace.</div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q3</div>
                                        <div class="item-detail">Launch of D-P2P </div>
                                    </li>
                                    <li class="tl-item" ng-repeat="item in retailer_history">
                                        <div class="item-title">Q4</div>
                                        <div class="item-detail">
                                            Launch of D-Messenger
                                        </div>
                                    </li>
                                    <div className="d-flex flex-row justify-content-end mb-5">
                                        <div>
                                            <a href={Milestone} target="_blank" class="roadmap-button-1" type="submit">Milestone</a>
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </p>

                    </div>
                </div>
            </div>













        </div>
    );
}
