import React from 'react'
import axios from 'axios' // axios import here
import { API_URL } from '../config/config'
import Swal from 'sweetalert2'
import $ from 'jquery'

import Blog from '../Pages/Separate/Blog'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailError: '',
      errors: {},
    }
  }

  // email handle change functions and API integration
  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    })
    console.log('event.target.value============', event.target.value)

    this.validationcheck(event.target.value)
  }

  validationcheck = (value) => {
    let isValid = true
    let emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
    // let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    console.log('value============', value)
    if (value == '') {
      this.setState({
        emailError: 'Email field is required',
      })
      isValid = false
    } else if (this.state.email == '' || this.state.email === undefined) {
      this.setState({
        emailError: 'Email field is required',
      })
      isValid = false
    } else if (!emailReg.test(this.state.email)) {
      this.setState({
        emailError: 'Email Address does not appear to be valid',
      })
      isValid = false
    }
    // else if (!emailReg.test(value)){
    //   this.setState({ emailError: "" });
    // }
    else {
      this.setState({ emailError: '' })
    }
    return isValid
  }

  handleSubmit = async (event) => {
    console.log('event========', event)
    event.preventDefault()
    var validstatus = this.validationcheck()
    console.log('validstatus=========', validstatus)
    if (validstatus) {
      axios({
        method: 'POST',
         url: `${API_URL}subscription/sub_add`,
        data: {
          email: this.state.email,
        },
      })
        .then((data) => {
          if (data.data) {
            this.setState({ email: '' })
          }
          Swal.fire({
            // title: "Wow!",
            title: 'Thank You for the Subscription!!',
            text:
              'It is our pleasure to have you as a valuable member of our D-Ecosystem Community',
            icon: 'success',
          })
        })
        .catch((err) => console.log(err))
    }
  }

  render() {
    return (
      <div className="App">
        <div className="becomeabanner-bg-css">
          <section className="subscription-content  internal-page-padding-top pb-3">
            <div className="container  d-chain-pos-banner-section ">
              {/* <p className="banner-text-2 text-start">D-CHAIN</p> */}
              <div className="d-flex flex-column justify-content-center text-center align-items-center">
                <div className="">
                  <h2 className=" fw-bold mb-5">Experience the Future Now</h2>
                </div>
                <div className="text-center ">
                  <p class="">
                  Try Our Beta Version of Products on D-Chain Test-Net
                  </p>
                </div>
              </div>

              <form
                onSubmit={this.handleSubmit}
                class="subscription-form p-3"
                autocomplete="off"
              >
                <div className="row align-items-start">
                  <div className="col-lg-8 p-0">
                    <input
                      type="email"
                      className="form-control subscription-email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Mail"
                      value={this.state.email}
                      onChange={this.handleChange}
                    ></input>
                    <span className="" style={{ color: 'red' }}>
                      {this.state.emailError}
                    </span>
                  </div>
                  <div className="col-lg-4 p-0">
                    <button type="submit" className="subscription-button">
                    Explore test version
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <section className="blog-section banner-section-7 py-3">
            <Blog />
          </section>
        </div>
      </div>
    )
  }
}

export default App
