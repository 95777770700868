import React from "react";

import dapp from "../Assests/images/banner/dapp/dapp-kit.png"
import dapp1 from "../Assests/images/banner/dapp/1.jpg"
import dapp2 from "../Assests/images/banner/dapp/2.jpg"
import dapp3 from "../Assests/images/banner/dapp/3.jpg"
import DChainStarterKit from "../Assests/D-Chain Starter Kit.rar"



class App extends React.Component {


  render() {
    return (
      <div className="App">



        <section className=" internal-page-padding-top pb-5">
          <div className="container d-chain-pos-banner-section">
            {/* <p className="banner-text-2 text-start">D-CHAIN</p> */}
            <div class="row">
              <div class="col">
                <main>
                  <aside class="sidebar-left">
                    <ol className="p-0 m-0 mb-5">
                      <li>
                        <nav id="navbar-example3" class="h-100 flex-column align-items-stretch">
                          <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-2" href="#item-1-1">Starter Kits</a>
                            <a class="nav-link ms-2" href="#item-1-2">Create your DAPP</a>
                            <a class="nav-link ms-2" href="#item-1-3">Truffle</a>
                          </nav>
                        </nav>
                      </li>


                    </ol>
                  </aside>
                  <section className="d-chain-scroll-container">
                    <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" class="scrollspy-example-2" tabindex="0">
                      <div id="item-1-1">
                        <h4 className="mt-0">Starter Kits</h4>
                        <p>D-Chain Starter Kit
                          <a download="D-Chain Starter Kit"  className="DChainStarterKit mx-1" href={DChainStarterKit} title="D-Chain Starter Kit">
                            (Download Now)
                          </a>


                          Tutorial containing React, @web3-react, Alchemy.</p>
                        <h5 className="mt-0">0. Environment Setup</h5>
                        <p>
                          Install fundamental environments, including node, web3, react, truffle, etc
                          \0. Environment Setup
                        </p>
                        <h5 className="mt-0">Install Ganache、Truffle</h5>
                        <p>
                          Use Starter Kits build your DAPP right away!<br />
                          Use Starter Kits build your DAPP right away!
                          This Starter Kit Tutorial containing React, @web3-react, Alchemy.
                        </p>

                        <h5>Install ganache</h5>
                        <p>Download Package from https://www.trufflesuite.com/ganache</p>
                        <img style={{ width: "70%" }} src={dapp} alt="dapp" />
                        <p>granache is needed for dapp development on local blockchain, its capable of viewing txns informations, create workspace folder, deploy smart contracts and tracking its interface.</p>
                        <h5>Install truffle</h5>
                        <p>npm install -g truffle <br />
                          truflle is used for develop, test, and deploy smart contracts.
                        </p>
                        <p>More tutorials about truffle: https://learnblockchain.cn/docs/truffle/<br />
                          \0. Environment Setup
                        </p>
                      </div>

                      <div id="item-1-2">

                        <h4>
                          Create your DAPP using Starter Kits Template
                        </h4>
                        <h5>Quick Start</h5>
                        <p>
                          npx create-react-app YOUR_PROJECT_NAME<br />
                          cd YOUR_PROJECT_NAME<br />
                          npm run start<br />
                          (npx comes from npm 5.2+ or later)<br />

                          Then fireup your browser and go to http://localhost:3000/ to check your application.
                          When you are preparing to deploy your project to production settings, use npm run build to create a compressed bundle and deploy.
                        </p>
                        <h5>Immediate Configuration</h5>
                        <p>There is no need for you to install or configurate tools like Webpack or Babel. They comes pre-configurated and hiddened, therefore you are provided with the full environment pack where you only need to worry about coding part.</p>
                        <p>Just create a project with the template, then you are good to go!
                        </p>
                        <h5>Create Application</h5>
                        <p>You will need to use Node that is higher or equal to version 6 on your local computer (on server you don't have to). There is nvm(macOS/Linux) or nvm-windows to help you eaily switch between different Node versions.</p>
                        <h5>Create a new application</h5>
                        <p>
                          npx create-react-app YOUR_PROJECT_NAME -starter-kit<br />
                          Then a new folder named YOUR_PROJECT_NAME will be created under current folder location.
                          <br /> The File structures under this new folder is as below:
                        </p>
                        <img style={{ width: "60%" }} src={dapp1} alt="dapp" />

                        <p>
                          YOUR_PROJECT_NAME
                        </p>
                      </div>

                      <div id="item-1-3">

                        <h4>Truffle Environment Configuration</h4>
                        <p>truffle-config.js<br />
                          const mnemonic = process.env.MNEMONIC;<br />
                          const HDWalletProvider = require("@truffle/hdwallet-provider");<br />
                        </p>
                        <img style={{ width: "60%" }} src={dapp3} alt="dapp" />

                        <p>
                          Create .env File under root folder
                        </p>
                        <h4>1.Project Structure</h4>
                        <p>Polygon-Starter-Kit Project Template Introduction, and how to utilize different modules in it.<br />
                          1.Project Structure
                        </p>
                        <h5>Project Structure</h5>
                        <h5>D-Chain-Kit Project Structure is as below</h5>
                        <img style={{ width: "60%" }} src={dapp2} alt="dapp" />

                        <ul>
                          <li>migrations is used to contain JS scripts for smart contract migration and deployment</li>
                          <li>src DAPP Client source code</li>
                          <li>src/contracts For containing smart contract,and its also the contract location pointer in the truffle configuration</li>
                          <li>src/abis for containing abi files after Truffle Compilation</li>
                          <li>hooks/index.js initialize Provider‘s request hook in @web3-react</li>
                        </ul>
                      </div>


                    </div>


                  </section>

                </main>
              </div>
            </div>
          </div>
        </section>





      </div>
    );
  }
}

export default App;
