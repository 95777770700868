import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DarkModeToggle from "react-dark-mode-toggle";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from "../../Assests/images/main-logo.png"
import $ from "jquery"
import { BsDiscord, BsHddNetworkFill, BsMedium } from 'react-icons/bs';
import { BiCoin, BiNews, BiCoinStack } from 'react-icons/bi';
import { HiLink, HiOutlineNewspaper, HiDocumentText, HiUserGroup } from 'react-icons/hi';
import { IoMdLink } from 'react-icons/io';
import { MdOutlineKitchen, MdOutlineEventRepeat, MdOutlineVerifiedUser, MdOutlineVerticalSplit, MdMediation, MdPlaylistAddCheckCircle } from 'react-icons/md';
import { GiWallet, GiAutomaticSas } from 'react-icons/gi';
import { BsFillCloudCheckFill } from 'react-icons/bs';
import { TbCircleArrowUpRightFilled } from 'react-icons/tb';
import { AiFillTwitterCircle, AiOutlineSwap } from 'react-icons/ai';
import { TbBrandTorchain, TbNetwork, TbMessages, TbManualGearbox } from 'react-icons/tb';
import { SiBlockchaindotcom, SiChainlink, SiHiveBlockchain, SiMarketo, SiGooglehome, SiAirplayaudio } from 'react-icons/si';
import { IoMoonSharp, IoSunny, IoWallet } from 'react-icons/io5';
import { AiFillCopy, AiFillCaretRight, AiFillLinkedin, AiFillFacebook, AiFillGithub, AiFillYoutube, AiOutlineCompass, AiOutlineDisconnect, AiOutlineCodeSandbox, AiFillInfoCircle } from 'react-icons/ai';
import { FaTelegram, FaNetworkWired, FaMicroblog } from 'react-icons/fa';
import gif1 from "../../Assests/images/banner/gif.gif"
import gif3 from "../../Assests/images/final.gif"
import WhitePaper from "../../Assests/White-Paper.pdf"
import litepaper from "../../Assests/lite paper.pdf"
import appapk from "../../Assests/app-analytics.apk"
import DwalletExtension2024 from "../../Assests/D-wallet-Extension-2024.zip"
import Marquee from "../Common/Marquee"
import Svg from "../Separate/Svg"
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';
import ConfettiExplosion from "react-confetti-explosion";

import DAOStarter from '../../Assests/images/Links/DAOStarter.png'
import KDG from '../../Assests/images/Links/KDG.svg'
import Gagarin from '../../Assests/images/Links/Gagarin.svg'
import DAOLaunch from '../../Assests/images/Links/DAOLaunch.png'
import { FiDownload } from "react-icons/fi";

import Web3 from 'web3';



class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: "dark-mode",
      isDarkMode: false,
      show: true
    };
  }


  componentDidMount() {
    // var myModal = new bootstrap.Modal(document.getElementById('launchedmodal'), {})

    window.onload = () => {
      // $('#launchedmodal').myModal.show()
      // $('#launchedmodal').addClass('show').css({ "display": "block" });
      // $("body").css({"height": "100vh","overflow": "hidden"});
    }


    $("#btn-close-announce").click(function () {
      // $('#launchedmodal').removeClass('show').css({ "display": "none" });
      // $("body").css({"height": "auto","overflow": "visible"});

    });





    $(window).scroll(function () {
      var wscroll = $(this).scrollTop();
      if (wscroll > 40) {
        $(".navbar").hide();
      }
      else {
        $(".navbar").show();
      }
    });
    $(window).on('load', function () {
      // $('#launchedmodal').modal('show');
    });



    $(".pos-main-item-section").hover(function () {
      $('.pos-sub-item-section').show();
      $('.comingsoon-sub-item-section').hide();
      $('.wallet-sub-item-section').hide();
    });
    $(".wallet-main-item-section").hover(function () {
      $('.pos-sub-item-section').hide();
      $('.comingsoon-sub-item-section').hide();
      $('.wallet-sub-item-section').show();
    });
    $(".comingsoon-main-item-section").hover(function () {
      $('.pos-sub-item-section').hide();
      $('.comingsoon-sub-item-section').show();
      $('.wallet-sub-item-section').hide();
    });
    $('.DCX-2-sub-item-section').hide();
    $('.DCX-3-sub-item-section').hide();
    $(".DCX-1-main-item-section").hover(function () {
      $('.DCX-1-sub-item-section').show();
      $('.DCX-2-sub-item-section').hide();
      $('.DCX-3-sub-item-section').hide();
    });
    $(".DCX-2-main-item-section").hover(function () {
      $('.DCX-1-sub-item-section').hide();
      $('.DCX-2-sub-item-section').show();
      $('.DCX-3-sub-item-section').hide();
    });
    $(".DCX-3-main-item-section").hover(function () {
      $('.DCX-1-sub-item-section').hide();
      $('.DCX-2-sub-item-section').hide();
      $('.DCX-3-sub-item-section').show();
    });


    if (localStorage.getItem('mode') === 'dark-mode') {
      this.setState({ theme: "dark-mode" });
      this.setState({ isDarkMode: true });
      $('body').addClass("dark-mode");
      $('body').removeClass("light-mode");
    }
    else {
      this.setState({ theme: "light-mode" });
      this.setState({ isDarkMode: false });
      $('body').addClass("light-mode");
      $('body').removeClass("dark-mode");
    }

  }
  handleToggle = () => {
    if (this.state.theme === "light-mode") {
      this.setState({ theme: "dark-mode" });
      this.setState({ isDarkMode: true });
      localStorage.setItem('mode', "dark-mode");
      $('body').addClass("dark-mode");
      $('body').removeClass("light-mode");
    }
    if (this.state.theme === "dark-mode") {
      this.setState({ theme: "light-mode" });
      localStorage.setItem('mode', "light-mode");
      this.setState({ isDarkMode: false });
      $('body').addClass("light-mode");
      $('body').removeClass("dark-mode");
    }
  };

  addchain = async () => {

    const web3js = new Web3(window.ethereum);
    if (!window.ethereum) {
      Swal.fire('Metamask not detected');
    } else {
      const chain = await web3js.eth.getChainId();

      const chainId = 5272 // Polygon Mainnet

      if (chainId !== chain) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3js.utils.toHex(chainId) }]
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            const chainIdnumber = '5272';
            const rpcURL = 'https://mainnetcoin.d-ecosystem.io';
            const networkName = 'D Chain Mainnet';
            const currencyName = 'DCX';
            const currencySymbol = 'DCX';
            const explorerURL = 'https://mainnet-explorer.d-ecosystem.io';
            const chainId = await web3js.utils.toHex(chainIdnumber);

            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chainId,
                  chainName: networkName,
                  rpcUrls: [rpcURL],
                  blockExplorerUrls: [explorerURL],

                  nativeCurrency: {
                    name: currencyName,
                    symbol: currencySymbol,
                    decimals: 18,
                  },
                },
              ],
            });
          }
        }
      }
      else {
        Swal.fire("You're already connected to D Chain Mainnet");
      }
    }

  }



  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  setisDarkMode = () => {
    this.setState({ isDarkMode: true });
  }

  render() {
    return (
      <>
        <div >

          {/* <Svg /> */}
          <Marquee />
          <Navbar key="xl" expand="xl" className="p-0 px-lg-0 px-2 fixed-top bg-transparent navbar-fixed-top-section">
            <Container fluid>
              <Navbar.Brand className='navbar-brand' href="/"><img src={Logo} alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-xl`}
                aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                placement="end"
              >
                <Offcanvas.Header closeButton >
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    <Navbar.Brand className='navbar-brand' href="/"><img src={Logo} alt="logo" /></Navbar.Brand>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={this.state.theme + " align-items-center"}>
                  <Nav className="justify-content-start flex-grow-1 pe-3">
                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Resolution
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6 d-lg-block d-none'>
                              <li className='pos-main-item-section py-3'><a class="dropdown-item-megadropdown">
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <SiBlockchaindotcom className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>Blockchain</p>
                                    {/* <p className='deco-text-2 hidden'>Loreum</p> */}
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </a></li>
                              <li className='wallet-main-item-section py-3'><a class="dropdown-item-megadropdown">
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <IoWallet className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>Wallet</p>
                                    {/* <p className='deco-text-2'>Loreum</p> */}
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </a></li>
                              <li className='comingsoon-main-item-section py-3'><a class="dropdown-item-megadropdown">
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <AiOutlineCompass className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>Coming Soon</p>
                                    {/* <p className='deco-text-2'>Loreum</p> */}
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </a></li>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" href="/d-chain">
                                <li className='pos-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiChainlink className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Chain (PoS)</p>
                                      <p className='deco-text-2'>Hard Fork of EVM</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="https://www.Airdrop.D-ecosystem.io">
                                <li className='pos-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiOutlineDisconnect className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Get D-Coin - Main Net</p>
                                      <p className='deco-text-2'>Purchase D-Coin on Mainnet</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="https://mainnet-explorer.d-ecosystem.io/">
                                <li className='pos-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiOutlineDisconnect className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Explore D-Chain - Main Net </p>
                                      <p className='deco-text-2'> D-Chain Scan to Explore transaction on D-Chain </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="/comingsoon">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiHiveBlockchain className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Get D-Coin - Faucet </p>
                                      <p className='deco-text-2'>Get Testnet Faucet </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="/comingsoon">
                                <li className='pos-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <TbBrandTorchain className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Explore D-Chain - Test Net</p>
                                      <p className='deco-text-2'>(coming soon)</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="https://www.d-wallet.io/ ">
                                <li className='wallet-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <GiWallet className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Wallet - Gateway to dApp and DeFi</p>
                                      <p className='deco-text-2'>Extension, Android and iOS (coming soon)</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="https://network.d-wallet.io/ ">
                                <li className='wallet-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <FaNetworkWired className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Networks</p>
                                      <p className='deco-text-2'>Add networks to your D-wallet in one click</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a target="_blank" class="dropdown-item-megadropdown" href="https://www.d-wallet.io/networks">
                                <li className='wallet-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <TbNetwork className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Add Network</p>
                                      <p className='deco-text-2'>Have list of RPC of all EVM- add Manually to your wallet </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              {/* <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiOutlineSwap className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>SWAP application</p>
                                      <p className='deco-text-2'>SWAPS coins and tokens (Interchain and Intrachain)</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a> */}
                              <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <BiCoin className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>DUSD</p>
                                      <p className='deco-text-2'>Stable currency with USD value and backed by Gold for stability </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              {/* <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <IoMdLink className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Bridge Application </p>
                                      <p className='deco-text-2'>Connects Various EVM blockchain</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a> */}
                              <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiMarketo className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>NFT Marketplace </p>
                                      <p className='deco-text-2'>Buy, sell and Mint Arts and Files to stay forever without loosing it</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <HiLink className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-P2P</p>
                                      <p className='deco-text-2'>removes the barrier between Crypto and FIAT</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="#">
                                <li className='comingsoon-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <TbMessages className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Messenger </p>
                                      <p className='deco-text-2'>Enhanced privacy based chat app with various solutions</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>


                          </div>
                        </ul>
                      </div>
                    </Nav>
                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Techies
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6'>
                              <p className='deco-text-4'>Techies</p>
                              <p className='deco-text-5'>Join Our Active Developer Community and Contribute towards the decentralized community.</p>
                              <div className='text-center'>
                                <img src={gif1} alt="gif" className='developer-gif-1' />
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" href={WhitePaper} target="_blank">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <HiOutlineNewspaper className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Technical White Paper</p>
                                      <p className='deco-text-2'>Download /view White paper </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/dapp-application-kit">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdOutlineKitchen className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>dApp Application Kit </p>
                                      <p className='deco-text-2'>Helps developer to develop Smart contracts</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="https://github.com/decosystem">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiFillGithub className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>GitHub</p>
                                      <p className='deco-text-2'>Explore our opensource codes</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" href="/comingsoon">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiOutlineCodeSandbox className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Ecosystem Venture</p>
                                      <p className='deco-text-2'>(Coming Soon)</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" href="/crew">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <HiUserGroup className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Crew</p>
                                      <p className='deco-text-2'>Meet the Crew</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </Nav>

                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Network
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6'>
                              <p className='deco-text-4'>Network</p>
                              <p className='deco-text-5'>Get involved with our community online through our social Media.</p>
                              <div className='navbar-community-social-links'>
                                <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem"><AiFillTwitterCircle /></a>
                                <a href="https://www.linkedin.com/company/d-ecosystem-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillLinkedin /></a>
                                <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillFacebook /></a>
                                <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub /></a>
                                <a href="https://t.me/d_ecosystem_group" target="_blank" alt="decosystem" rel="noopener noreferrer"><FaTelegram /></a>
                                <a href="https://www.youtube.com/@d-ecosystemchannel5605" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillYoutube /></a>
                                <a href="https://discord.com/invite/TacRQNaVdH" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsDiscord /></a>
                                <a href="https://medium.com/@decosystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsMedium /></a>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" href="/event">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdOutlineEventRepeat className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Events</p>
                                      <p className='deco-text-2'>Participate or MEET at our events</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/career">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdOutlineVerifiedUser className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'> Ecosystem Jobs</p>
                                      <p className='deco-text-2'>Enhance your career with D-Ecosystem</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </Nav>

                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Assets
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6'>
                              <p className='deco-text-4'>Assets</p>
                              <p className='deco-text-5'>Get the most recent news, product updates, and many more.</p>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" href={WhitePaper} target="_blank">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <HiOutlineNewspaper className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>White Paper</p>
                                      <p className='deco-text-2'>Download/view white paper </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href={litepaper} target="_blank">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdOutlineVerticalSplit className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Lite Paper</p>
                                      <p className='deco-text-2'> Download/view Lite paper </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" href="https://www.fairyproof.com/doc/D-Chain-Audit-Report-050423.pdf" target="_blank">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <HiDocumentText className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-chain Audit Report</p>
                                      <p className='deco-text-2'> Fairyproof D-chain Audit Report </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" target="_blank" href="https://medium.com/@decosystem.io">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <FaMicroblog className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Blog</p>
                                      <p className='deco-text-2'>Get latest updates on it</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <BiNews className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Subscribe newsletter</p>
                                      <p className='deco-text-2'>Subscribe now to get latest instant updates on D-Ecosystem</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </Nav>
                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          DAO
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6'>
                              <p className='deco-text-4'>DAO</p>
                              <p className='deco-text-5'>
                                D-Ecosystem DAO is a decentralized autonomous organization, a kind of bottom-up entity structure with no central authority. Members of D-Ecosystem DAO own tokens of the D-Ecosystem DAO and members can vote on initiatives for the D-Ecosystem DAO. Smart contracts are and will be implemented for the D-Ecosystem DAO, and the code governing the operation of the D-Ecosystem DAO will be publicly announced.
                              </p>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" href="https://dao.d-ecosystem.io/" target="_blank">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiBlockchaindotcom className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>DAO Center</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/aboutus">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiGooglehome className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>About US</p>
                                      <p className='deco-text-2'>We empowers decentralisation and Financial Freedom </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/d-chain">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiChainlink className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Chain</p>
                                      <p className='deco-text-2'>PoS based Blockchain with Virtual machine</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/mediakit">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdMediation className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Media Kit</p>
                                      <p className='deco-text-2'>Dowload the source and use it for your dApp and DeFi</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/career">
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <MdPlaylistAddCheckCircle className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Career</p>
                                      <p className='deco-text-2'>Join our team </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </Nav>
                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Enjoy with D-Coin (DCX)
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content">
                          <div className='row'>
                            <div className='col-lg-6 d-lg-block d-none'>

                              <li className='DCX-1-main-item-section'>
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <SiBlockchaindotcom className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>D-Coin</p>
                                    <p className='deco-text-2'>Explore Now</p>
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </li>

                              <li className='DCX-2-main-item-section'>
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <IoWallet className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>Products</p>
                                    <p className='deco-text-2'>Explore Now</p>
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </li>

                              <li className='DCX-3-main-item-section'>
                                <div className='d-flex flex align-items-center'>
                                  <div>
                                    <BsHddNetworkFill className='deco-text-3' />
                                  </div>
                                  <div>
                                    <p className='deco-text-1'>Add Network</p>
                                    <p className='deco-text-2'>Explore Now</p>
                                  </div>
                                  <div className='ms-auto'>
                                    <AiFillCaretRight className="deco-icon-1" />
                                  </div>
                                </div>
                              </li>
                            </div>
                            <div className='col-lg-6'>
                              <a class="dropdown-item-megadropdown" target="_blank" href="https://Airdrop.D-Ecosystem.io">
                                <li className='DCX-1-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiAirplayaudio className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Receive Airdrop</p>
                                      <p className='deco-text-2'> Recieve Free coins  </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" target="_blank"
                                href="https://launchpad.d-ecosystem.io/"
                              >
                                <li className='DCX-1-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <BiCoinStack className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Purchase Coin</p>
                                      <p className='deco-text-2'>Inter and Intrachain IDO </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" target="_blank" href="https://mainnet-explorer.d-ecosystem.io/">
                                <li className='DCX-1-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiHiveBlockchain className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Coin Explorer</p>
                                      <p className='deco-text-2'>Explore D-Coin Activity</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="https://validator.d-ecosystem.io/" target='_blank'>
                                <li className='DCX-1-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <BsFillCloudCheckFill className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Validator</p>
                                      <p className='deco-text-2'>Become a Validator</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" target="_blank" href="https://d-wallet.io/">
                                <li className='DCX-2-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <FaNetworkWired className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Wallet</p>
                                      <p className='deco-text-2'>GAteway to dApp and DeFi</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="https://defi.d-ecosystem.io/swap" target="_blank">
                                <li className='DCX-2-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <AiOutlineSwap className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-SWAP</p>
                                      <p className='deco-text-2'>Inter and Intra Chain that swaps tokens and coins </p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a class="dropdown-item-megadropdown" href="/comingsoon">
                                <li className='DCX-2-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiMarketo className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-NFT</p>
                                      <p className='deco-text-2'>(coming Soon)</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" data-bs-toggle="modal" data-bs-target="#manualconnect" style={{ cursor: "pointer" }}>
                                <li className='DCX-3-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <TbManualGearbox className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Manual Connecting</p>
                                      <p className='deco-text-2'>Network Details</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>

                              <a class="dropdown-item-megadropdown" onClick={this.addchain} style={{ cursor: "pointer" }}>
                                <li className='DCX-3-sub-item-section'>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <GiAutomaticSas className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>Automatic Connecting</p>
                                      <p className='deco-text-2'>Click to Connect</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </Nav>

                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <button class="navbar-mega-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Wallet
                        </button>
                        <ul class="dropdown-menu dropdown_megamenu-content dropdown_megamenu-content-2">
                          <div className='row'>
                            <div className='col-lg-12'>
                              <a href={appapk} download>
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <SiBlockchaindotcom className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Wallet Apk</p>
                                      <p className='deco-text-2'>Download Now</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a href={DwalletExtension2024} download>
                                <li>
                                  <div className='d-flex flex align-items-center'>
                                    <div>
                                      <IoWallet className='deco-text-3' />
                                    </div>
                                    <div>
                                      <p className='deco-text-1'>D-Wallet Extension</p>
                                      <p className='deco-text-2'>Download Now</p>
                                    </div>
                                    <div className='ms-auto'>
                                      <AiFillCaretRight className="deco-icon-1" />
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </div>

                          </div>
                        </ul>
                      </div>
                    </Nav>

                    <Nav className='ms-lg-2'>
                      <div class="dropdown">
                        <a href='/subscribe'>
                          <button class="navbar-mega-dropdown" type="button" aria-expanded="false">
                            Try our products
                          </button>
                        </a>
                      </div>
                    </Nav>


                  </Nav>

                  <Nav className="justify-content-end flex-grow-1 align-items-center">
                    <Nav className='me-lg-2'>
                      <a href={DwalletExtension2024} download>
                        <button class="navbar-mega-dropdown hack-top-nav-btn" type="button" aria-expanded="false">
                          <FiDownload className='me-2'/> D-Wallet Extension
                        </button>
                      </a>
                      {/* <a href='/hackathon'>
                        <button class="navbar-mega-dropdown hack-top-nav-btn" type="button" aria-expanded="false">
                          Hackathon
                        </button>
                      </a> */}
                    </Nav>
                    <div className='navbar-community-social-links mb-lg-0 mb-3'>
                      <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem"><AiFillTwitterCircle /></a>
                      <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub /></a>
                      <a href="https://t.me/d_ecosystem_group" target="_blank" alt="decosystem" rel="noopener noreferrer"><FaTelegram /></a>
                      <a href="https://discord.com/invite/TacRQNaVdH" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsDiscord /></a>
                    </div>
                    {/* <Nav.Link className='mode-change-navbar-button'>
                      <IoSunny className='modechange-sun-icon' onClick={this.handleToggle} />
                      <IoMoonSharp className='modechange-moon-icon' onClick={this.handleToggle} />
                      <DarkModeToggle onChange={this.handleToggle} checked={this.state.isDarkMode} size={80} />
                    </Nav.Link> */}
                    {/* <Nav.Link className='navbar-contact-us-button ms-lg-3' data-bs-toggle="modal" data-bs-target="#buyCoinModal">Buy D-Coin</Nav.Link> */}
                    <Nav.Link className='navbar-contact-us-button ms-md-0 ms-lg-0 ms-xll-3 mb-lg-0 mb-2' href="" onClick={this.addchain}>Add D-Chain</Nav.Link>
                    <Nav.Link className='navbar-contact-us-button ms-lg-3' href="https://sales.d-ecosystem.io/" target='_blank'>Buy D-Coin</Nav.Link>
                    {/* <Nav.Link href="/comingsoon" className='navbar-build-us-button ms-lg-3'>Build On D-Chain</Nav.Link> */}


                  </Nav>

                </Offcanvas.Body>

              </Navbar.Offcanvas>

            </Container>
          </Navbar>

          <div class="modal fade manualconnect-modal-section" id="buyCoinModal" tabindex="-1" aria-labelledby="buyCoinModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header mb-0 pb-0 border-0">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div className='d-flex text-center justify-content-center align-items-center mb-3'>
                    <h3 className=''>Select your desired platform <br /> To get DCX coin </h3>
                  </div>
                  <div className='container pb-3'>
                    <div className='row row-cols-lg-1 g-4'>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://www.daostarter.pro/#/DAOStarter/index'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOStarter} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAOstarter - July 3rd - July 7th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://app.gagarin.world/ido/649c236693ecbba890e6e8d1'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - July 10th - July 13th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://kingdomstarter.io/launchpad/upcoming/d-ecosystem'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={KDG} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Kingdom Starter - July 14th - July 16th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.notion.site/b0ab81647df84690980d93deaad13bfe?v=95a0950059d54f348455afc241baa712'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Dao Launch - July 17rd - July 20th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      {/* <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://gagarin.world/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - TBD</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.net/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAO Launch - TBD</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade manualconnect-modal-section" id="manualconnect" tabindex="-1" aria-labelledby="manualconnectLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0">
                  <h4 class="modal-title" id="manualconnectLabel">Network details</h4>
                  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div>
                    <p className='manualconnect-text-1'>Network name
                      <span className="my-anchor-element"> <AiFillInfoCircle className='manualconnect-icon-1' /></span>
                      <Tooltip anchorSelect=".my-anchor-element" className='manualconnect-tool-tip' place="top">
                        The name associated with this network.
                      </Tooltip>
                    </p>
                    <p className='manualconnect-text-2'>D Chain Mainnet</p>
                  </div>

                  <div>
                    <p className='manualconnect-text-1'>Network URL
                      <span className="my-anchor-element"> <AiFillInfoCircle className='manualconnect-icon-1' /></span>
                      <Tooltip anchorSelect=".my-anchor-element" className='manualconnect-tool-tip' place="top">
                        The URL used to access this network.
                      </Tooltip>

                    </p>
                    <span className='manualconnect-text-2' id="copyText1">https://mainnetcoin.d-ecosystem.io </span>

                    <CopyToClipboard text="https://mainnetcoin.d-ecosystem.io">
                      <AiFillCopy className='manualconnect-icon-1' id="Network-URL-copy" style={{ cursor: "pointer" }} />
                    </CopyToClipboard>

                  </div>

                  <div>
                    <p className='manualconnect-text-1 mt-4'>Chain ID
                      <span className="my-anchor-element"> <AiFillInfoCircle className='manualconnect-icon-1' /></span>
                      <Tooltip anchorSelect=".my-anchor-element" className='manualconnect-tool-tip' place="top">
                        The chain ID used to sign transactions for this network.
                      </Tooltip>
                    </p>
                    <p className='manualconnect-text-2'>5272</p>
                  </div>

                  <div>
                    <p className='manualconnect-text-1'>Currency symbol
                      <span className="my-anchor-element"> <AiFillInfoCircle className='manualconnect-icon-1' /></span>
                      <Tooltip anchorSelect=".my-anchor-element" className='manualconnect-tool-tip' place="top">
                        The ticker symbol displayed for this network’s currency.
                      </Tooltip>
                    </p>
                    <p className='manualconnect-text-2'>DCX</p>
                  </div>

                  <div>
                    <p className='manualconnect-text-1'>Block explorer URL
                      <span className="my-anchor-element"> <AiFillInfoCircle className='manualconnect-icon-1' /></span>
                      <Tooltip anchorSelect=".my-anchor-element" className='manualconnect-tool-tip' place="top">
                        The URL used as the block explorer for this network.
                      </Tooltip>
                    </p>
                    <span className='manualconnect-text-2'>https://mainnet-explorer.d-ecosystem.io </span>

                    <CopyToClipboard text="https://mainnet-explorer.d-ecosystem.io">
                      <AiFillCopy className='manualconnect-icon-1' id="Network-URL-copy" style={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        {/* 
        <div class="modal fade " id="launchedmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" role='dialog'>
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-transparent border-0">
              <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close" id="btn-close-announce" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img src={gif3} alt="gif" />

              </div>

            </div>
          </div>
        </div> */}

        {/* <div>

          <Modal show={this.state.show} onHide={this.handleClose} id="launchedmodal" centered>
            <div class="modal-content-inner">
              <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close btn-close-white" id="btn-close-announce" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
              </div>
              <div class="modal-body text-center">
                <p class="popup-text-1"><span class="fc-b">D-Launchpad</span> is </p>
                <p class="popup-text-2">LIVE</p>
                <p class="popup-text-3"><span class="fc-b">JOIN US</span> IN THE DIGITAL REVOLUTION </p>

                <a href="https://launchpad.d-ecosystem.io/" target="_blank" data-rr-ui-event-key="https://launchpad.d-ecosystem.io/" class="navbar-contact-popup-button">Buy DCX Coin</a>
              </div>
              <div class='hoverme'>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
              </div>

            </div>
          </Modal>
        </div> */}


      </>
    );
  }
}

export default App;