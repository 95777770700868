import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Brigitte from "../../Assests/images/crew-member/Brigitte-H.png"
import Deva from "../../Assests/images/crew-member/Deva.png"
import Elke from "../../Assests/images/crew-member/Elke-W.png"
import Freche from "../../Assests/images/crew-member/Freche-B.png"
import Aka from "../../Assests/images/crew-member/Aka.P - Toon.png"
import Andrea from "../../Assests/images/crew-member/Andrea C - Toon.png"
import Miro from "../../Assests/images/crew-member/Miro - Toon.png"
import Silvia from "../../Assests/images/crew-member/Silvia - Toon.png"
import Stemma from "../../Assests/images/crew-member/Stemma - Toon.png"
import Tatjana from "../../Assests/images/crew-member/Tatjana K - Toon.png"
import Akas from "../../Assests/images/crew-member/male/Akas - Toon.png"
import Andy from "../../Assests/images/crew-member/male/Andy - Toon.png"
import Christian from "../../Assests/images/crew-member/male/Christian. D - Toon.png"
import DavidK from "../../Assests/images/crew-member/male/David. K - Toon.png"
import DavidS from "../../Assests/images/crew-member/male/David. S - Toon.png"
import Emanuel from "../../Assests/images/crew-member/male/Emanuel - Toon.png"
import Etu from "../../Assests/images/crew-member/male/Etu - Toon.png"
import Gerald from "../../Assests/images/crew-member/male/Gerald - Toon.png"
import Jeko from "../../Assests/images/crew-member/male/Jeko - Toon.png"
import JonasS from "../../Assests/images/crew-member/male/Jonas. S - Toon.png"
import JonasK from "../../Assests/images/crew-member/male/Jonas - Toon.png"
import Juergen from "../../Assests/images/crew-member/male/Juergen - Toon.png"
import Kevin from "../../Assests/images/crew-member/male/Kevin - Toon.png"
import Kurt from "../../Assests/images/crew-member/male/Kurt - Toon.png"
import Markus from "../../Assests/images/crew-member/male/Markus - Toon.png"
import Nico from "../../Assests/images/crew-member/male/Nico - Toon.png"
import Pasqual from "../../Assests/images/crew-member/male/Pasqual - Toon.png"
import Paul from "../../Assests/images/crew-member/male/Paul - Toon.png"
import Thomas from "../../Assests/images/crew-member/male/Thomas - Toon.png"
import Weber from "../../Assests/images/crew-member/male/Weber. A - Toon.png"
import Mia from "../../Assests/images/crew-member/mia.png"
import Stefan from "../../Assests/images/crew-member/male/Stefan - Toon.png"


HighchartsMore(Highcharts);




function Bubble(props) {



    let options = {
        chart: {
            type: 'packedbubble',
            height: '60%'
        },
        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            borderColor: 'none',
            useHTML: true,
            pointFormat: '<div class="crew-tooltip-section"><b class="dao-text-1">{point.name}</b><p class="dao-text-3 dao-text-3-margin">{point.role}</p><p class="dao-text-3">{point.desc}</p></div>'
        },


        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            packedbubble: {
                minSize: '30%',
                maxSize: '100%',
                zMin: 0,
                zMax: 600,
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.02
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}',
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: 0
                    },
                    style: {
                        color: 'white',
                        textOutline: 'none',
                        fontWeight: 'normal'
                    }
                }
            }
        },


        series: [{
            colorByPoint: true,
            // point: {
            //     events: {
            //         click: function () {
            //             this.series.chart.update({
            //                 tooltip: {
            //                     enabled: true
            //                 }
            //             });
            //         },
            //         mouseOut: function () {
            //             this.series.chart.update({
            //                 tooltip: {
            //                     enabled: false
            //                 }

            //             })
            //         }
            //     }
            // },

            className: "graph-red",
            name: 'DAO Founder',
            color: 'rgba(27, 159, 254, 0.3)',
            fillColor: 'yellow',

            data: [{
                name: 'Nico K.',
                role: "DAO Founder",
                value: 199.4,
                marker: {
                    symbol: 'url(' + Nico + ')'
                }
              
            },
            {
                name: 'Weber A.',
                role: "DAO Founder",
                value: 192.1,
                marker: {
                    symbol: 'url(' + Weber + ')'
                },
            },
            {
                name: 'Christian D.',
                role: "DAO Founder",
                value: 187.1,
                marker: {
                    symbol: 'url(' + Christian + ')'
                },
            },
            {
                name: 'Kevin T.',
                role: "DAO Founder",
                value: 176.1,
                marker: {
                    symbol: 'url(' + Kevin + ')'
                },
            },
            {
                name: 'Etu G.',
                role: "DAO Founder",
                value: 170.1,
                marker: {
                    symbol: 'url(' + Etu + ')'
                },
            },
            {
                name: 'Juergen S.',
                role: "DAO Founder",
                value: 164.1,
                marker: {
                    symbol: 'url(' + Juergen + ')'
                },
            },
            {
                name: 'Gerald P.',
                role: "DAO Founder",
                value: 158.1,
                marker: {
                    symbol: 'url(' + Gerald + ')'
                },
            },
            {
                name: 'Tatjana K.',
                role: "DAO Founder",
                value: 150.1,
                marker: {
                    symbol: 'url(' + Tatjana + ')'
                },
            },
            {
                name: 'Aka P.',
                role: "DAO Founder",
                value: 154.1,
                marker: {
                    symbol: 'url(' + Aka + ')'
                },
            },
            {
                name: 'Deva X.',
                role: "DAO Founder",
                value: 148.1,
                marker: {
                    symbol: 'url(' + Deva + ')'
                },
            },
            {
                name: 'Jonas K.',
                role: "DAO Founder",
                value: 142.1,
                marker: {
                    symbol: 'url(' + JonasK + ')'
                },
            },
            {
                name: 'Stefan F.',
                role: "DAO Founder",
                value: 136.1,
                marker: {
                    symbol: 'url(' + Stefan + ')'
                },
            },
            {
                name: 'Stemma W.',
                role: "DAO Founder",
                value: 130.1,
                marker: {
                    symbol: 'url(' + Stemma + ')'
                },
            },
            {
                name: 'David K.',
                role: "DAO Founder",
                value: 127.1,
                marker: {
                    symbol: 'url(' + DavidK + ')'
                },
            },
            {
                name: 'Andreas S.',
                role: "DAO Founder",
                value: 121.1,
                marker: {
                    symbol: 'url(' + Andrea + ')'
                },
            },
            {
                name: 'Akas P.',
                role: "DAO Founder",
                value: 116.1,
                marker: {
                    symbol: 'url(' + Akas + ')'
                },
            },
            {
                name: 'Andy S.',
                role: "DAO Founder",
                value: 109.1,
                marker: {
                    symbol: 'url(' + Andy + ')'
                },
            },
            {
                name: 'Kurt W.',
                role: "DAO Founder",
                value: 101.1,
                marker: {
                    symbol: 'url(' + Kurt + ')'
                },
            },
            {
                name: 'Silvia H.',
                role: "DAO Founder",
                value: 97.1,
                marker: {
                    symbol: 'url(' + Silvia + ')'
                },
            },
            {
                name: 'Markus T.',
                role: "DAO Founder",
                value: 94.1,
                marker: {
                    symbol: 'url(' + Markus + ')'
                },
            },
            {
                name: 'Emanuel Z.',
                role: "DAO Founder",
                value: 90.1,
                marker: {
                    symbol: 'url(' + Emanuel + ')'
                },
            },
            {
                name: 'Elke W.',
                role: "DAO Founder",
                value: 87.1,
                marker: {
                    symbol: 'url(' + Elke + ')'
                },
            },
            {
                name: 'Jonas S.',
                role: "DAO Founder",
                value: 84.1,
                marker: {
                    symbol: 'url(' + JonasS + ')'
                },
            },
            {
                name: 'Freche B.',
                role: "DAO Founder",
                value: 80.1,
                marker: {
                    symbol: 'url(' + Freche + ')'
                },
            },
            {
                name: 'Andrea C.',
                role: "DAO Founder",
                value: 77.1,
                marker: {
                    symbol: 'url(' + DavidK + ')'
                },
            },
            {
                name: 'Miro W.',
                role: "DAO Founder",
                value: 74.1,
                marker: {
                    symbol: 'url(' + Miro + ')'
                },
            },
            {
                name: 'Jeko K.',
                role: "DAO Founder",
                value: 71.1,
                marker: {
                    symbol: 'url(' + Jeko + ')'
                },
            },
            {
                name: 'Paul R.',
                role: "DAO Founder",
                value: 70.1,
                marker: {
                    symbol: 'url(' + Paul + ')'
                },
            },
            {
                name: 'Pasqual B.',
                role: "DAO Founder",
                value: 68.1,
                marker: {
                    symbol: 'url(' + Pasqual + ')'
                },
            },
            {
                name: 'Brigitte H.',
                role: "DAO Founder",
                value: 65.1,
                marker: {
                    symbol: 'url(' + Brigitte + ')'
                },
            },
            {
                name: 'Thomas F.',
                role: "DAO Founder",
                value: 64.1,
                marker: {
                    symbol: 'url(' + Thomas + ')'
                },
            },
            {
                name: 'David S.',
                role: "DAO Founder",
                value: 63.1,
                marker: {
                    symbol: 'url(' + DavidS + ')'
                },
            },
            {
                name: 'Mia K.',
                role: "DAO Founder",
                value: 60.1,
                marker: {
                    symbol: 'url(' + Mia + ')'
                },
            }]
        }]
    }



    return (
        <HighchartsReact
            className="HighchartsReact-banner"
            highcharts={Highcharts}
            options={options}
            immutable={true}
        />
    );
}

export default Bubble;
