import React from 'react';
import { BsDiscord,BsMedium } from 'react-icons/bs';
import { AiFillTwitterCircle, AiFillGithub, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube,AiFillFacebook } from 'react-icons/ai';
import { FaTelegram, FaRedditAlien } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import Logo from "../../Assests/images/main-logo.png"
import kycverify from "../../Assests/images/kyc-d-eco.png"


class App extends React.Component {
  constructor(props) {
    super(props);

  }




  render() {
    return (
      <>

        <section class="footer-section-bottom">
          <div class="container py-4">
            <div class="row align-items-start justify-content-between g-3">
              <div class="col-lg-6 footer-logo-image ">
                <img class="navbar-brand-footer" src={Logo} alt="logo" />
                {/* <img class="navbar-brand-footer-kyc" src={kycverify} alt="logo" /> */}

              </div>
              <div class="col-lg-6 footer-social-icons-link ">
                <div className="d-flex flex-row flex-wrap justify-content-lg-end">
                  <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem"><AiFillTwitterCircle /></a>
                  <a href="https://www.linkedin.com/company/d-ecosystem-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillLinkedin /></a>
                  <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillFacebook /></a>
                  <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub /></a>
                  <a href="https://t.me/d_ecosystem_group" target="_blank" alt="decosystem" rel="noopener noreferrer"><FaTelegram /></a>
                  <a href="https://www.youtube.com/@d-ecosystemchannel5605" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillYoutube /></a>
                  <a href="https://discord.com/invite/TacRQNaVdH" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsDiscord /></a>
                  <a href="https://medium.com/@decosystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsMedium /></a>

                </div>
              </div>
            </div>
            <div class="row align-items-start g-3 mt-5">

              <div class="col-lg-3">
                <div class="list-group footer-typo footer-typo-mb">
                  <h3 class="footer-text-1">Our Products</h3>
                  <a href='/d-chain'>D-Chain <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a target="_blank" href="https://mainnet-explorer.d-ecosystem.io/">Mainnet Explorer <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a target="_blank" href="https://testnet-explorer.d-ecosystem.io/">Testnet Explorer <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a target="_blank" href="https://defi.d-ecosystem.io/swap">D-Swap <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a target="_blank" href="https://d-wallet.io/">D-Wallet <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/comingsoon">D-Nft <BiRightArrowAlt class="footer-typo-icons" /></a>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="list-group footer-typo footer-typo-mb">
                  <h3 class="footer-text-1">Developer</h3>
                  <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer">Github <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/comingsoon">Forum <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/comingsoon">Bug Bounty <BiRightArrowAlt class="footer-typo-icons" /></a>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="list-group footer-typo footer-typo-mb">
                  <h3 class="footer-text-1">General</h3>
                  <a href="/aboutus">About <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="https://medium.com/@decosystem.io"  target="_blank" rel="noopener noreferrer">Blog <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/career" rel="noopener noreferrer">Career <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/contactus"  rel="noopener noreferrer">Contact Us <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href='/mediakit'>Media Kit <BiRightArrowAlt class="footer-typo-icons" /></a>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="list-group footer-typo footer-typo-mb">
                  <h3 class="footer-text-1">Legal</h3>
                  <a href="/termsofuse">Terms of Use <BiRightArrowAlt class="footer-typo-icons" /></a>
                  <a href="/privacypolicy">Privacy Policy <BiRightArrowAlt class="footer-typo-icons" /></a>
                </div>
              </div>




            </div>
          </div>

        </section>
      </>
    );
  }
}

export default App;