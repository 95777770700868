import React, { useState, useMemo } from "react";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import axios from "axios";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import Swal from 'sweetalert2'

export default function Form() {

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "rgb(2,17,40)" : "rgb(2,45,68)",
        color:"white"
      };
    },
  
    control: (base, state) => ({
      ...base,
      background: "rgba(27, 159, 254, 0.1)",
      // padding: "6px",
      // match with the menu
      borderRadius: state.isFocused ? "7px 7px 7px 7px" : "7px 7px 7px 7px",

      padding:state.isFocused ? "6px" : "6px",
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#00e5ff21" : "#00e5ff21",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#00e5ff21" : "#00e5ff21"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
    
      padding: 0
    })
 
  };

  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])
  const [hackathon, setHackathon] = useState({
    support: 0,
    nft: 0,
    defi: 0,
    frontiers: 0,
    advancement: 0,
    gaming: 0
  });
  const { handleChange, handleSubmit, handleBlur, touched, errors, values, resetForm, setValues, } = useFormik({
    initialValues: {
      name: "",
      website: "",
      description: "",
      country: "",
      year: "",
      teamsize: "",
      teamleadername: "",
      teamleaderemail: "",
      teamleaderprofile: "",
      member: "",
      experience: 0,
      overview: "",
      visionary: "",
      question1: "",
      question2: "",
      question3: "",
      confirmation1: false,
      confirmation2: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(" Name is required!"),
      website: Yup.string().required(" website is required!"),
      description: Yup.string().required(" description is required!"),
      country: Yup.string().required(" country is required!"),
      year: Yup.string().required(" year is required!"),
      teamsize: Yup.string().required(" teamsize is required!"),
      teamleadername: Yup.string().required(" teamleadername is required!"),
      teamleaderemail: Yup.string().email("Please enter valid email")
      .required("E-mail is required"),
      teamleaderprofile: Yup.string().required(" teamleaderprofile is required!"),
      member: Yup.string().required(" member is required!"),
      overview: Yup.string().required(" overview is required!"),
      visionary: Yup.string().required(" visionary is required!"),
      question1: Yup.string().required(" question1 is required!"),
      question2: Yup.string().required(" question2 is required!"),
      question3: Yup.string().required(" question3 is required!"),
      confirmation1: Yup.boolean().oneOf([true], "You must accept the terms and conditions!"),
      confirmation2: Yup.boolean().oneOf([true], "You must accept the terms and conditions!"),
    }),
    onSubmit: async (formValues) => {
      try {
        let payload = {
          name: formValues.name,
          website: formValues.website,
          description: formValues.description,
          country: formValues.country,
          year: formValues.year,
          teamsize: formValues.teamsize,
          teamleadername: formValues.teamleadername,
          teamleaderemail: formValues.teamleaderemail,
          teamleaderprofile: formValues.teamleaderprofile,
          member: formValues.member,
          overview: formValues.overview,
          visionary: formValues.visionary,
          question1: formValues.question1,
          question2: formValues.question2,
          question3: formValues.question3,
          confirmation1: formValues.confirmation1,
          confirmation2: formValues.confirmation2
        };
        payload.hackathon = hackathon
        console.log("payload", payload);
        await axios.post("https://panel.d-ecosystem.io/createhackathon", payload).then((res) => {
          if (res.data.status) {
            Swal.fire({
              // title: "Wow!",
              text: 'Registrated Successfully!',
              icon: 'success',
            })
            setTimeout(() => window.location.reload(), 2000)
          }
        }).catch((err) => {
          console.log("err", err.message);
        })
      } catch (error) {
        console.log("error", error);
      }
    }
  });
  console.log("vvvvvv", values);
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setHackathon((prevData) => ({
      ...prevData,
      [name]: checked ? 1 : 0
    }));
  }
  const changeHandler = value => {
    setValue(value);
    setValues((prevData) => ({ ...prevData, country: value.label }))
  }
  return (
    <>
      <section className="hackathon-form-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">

              <div className="text-center">
                <h3>VISIONARY Hackathon 2024: Startup Registration Form</h3>
                <p>
                  {" "}
                  Welcome to the VISIONARY Hackathon 2024 registration. Please
                  fill out the following details to sign up your startup for the
                  event. Let's innovate together on D-Chain!
                </p>
              </div>

              <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                return false;
              }}>

                <div className="row">
                  <h4 className=" my-3">Startup Information</h4>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Startup Name
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.name && errors.name ? true : false}
                      />
                    </div>
                    {touched.name && errors.name && (
                      <small className="error">
                        {errors.name}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Startup Website (if applicable)
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="website"
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.website && errors.website ? true : false}
                      />
                    </div>
                    {touched.website && errors.website && (
                      <small className="error">
                        {errors.website}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Description of Startup
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.description && errors.description ? true : false}
                      />
                    </div>
                    {touched.description && errors.description && (
                      <small className="error">
                        {errors.description}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Country of Incorporation
                    </label>
                    <div class="input-group">
                      <Select
                        className="countryPicker"
                        options={options}
                        value={value}
                        onChange={changeHandler}
                        styles={customStyles}
                        invalid={touched.country && errors.country ? true : false}
                      />
                    </div>
                    {touched.country && errors.country && (
                      <small className="error">
                        {errors.country}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col">
                    <label for="basic-url" class="form-label">
                      Year Founded
                    </label>
                    <div class="input-group">
                      <input
                        type="number"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.year && errors.year ? true : false}
                      />
                    </div>
                    {touched.year && errors.year && (
                      <small className="error">
                        {errors.year}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4 className=" my-3">Team Information</h4>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Team Size
                    </label>
                    <div class="input-group">
                      <input
                        type="number"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="teamsize"
                        value={values.teamsize}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.teamsize && errors.teamsize ? true : false}
                      />
                    </div>
                    {touched.teamsize && errors.teamsize && (
                      <small className="error">
                        {errors.teamsize}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Team Leader's Name
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="teamleadername"
                        value={values.teamleadername}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.teamleadername && errors.teamleadername ? true : false}
                      />
                    </div>
                    {touched.teamleadername && errors.teamleadername && (
                      <small className="error">
                        {errors.teamleadername}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Team Leader's Contact Email
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="teamleaderemail"
                        value={values.teamleaderemail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.teamleaderemail && errors.teamleaderemail ? true : false}
                      />
                    </div>
                    {touched.teamleaderemail && errors.teamleaderemail && (
                      <small className="error">
                        {errors.teamleaderemail}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Team Leader's LinkedIn Profile (optional)
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="teamleaderprofile"
                        value={values.teamleaderprofile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.teamleaderprofile && errors.teamleaderprofile ? true : false}
                      />
                    </div>
                    {touched.teamleaderprofile && errors.teamleaderprofile && (
                      <small className="error">
                        {errors.teamleaderprofile}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col">
                    <label for="basic-url" class="form-label">
                      Names and Roles of Team Members
                    </label>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="Names and Roles of Team Members"
                        id="floatingTextarea"
                        name="member"
                        value={values.member}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.member && errors.member ? true : false}
                      ></textarea>
                    </div>
                    {touched.member && errors.member && (
                      <small className="error">
                        {errors.member}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4 className=" my-3">Hackathon Details</h4>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Which category(ies) are you interested in? (Select all that
                      apply)
                    </label>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        name="support"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Ecosystem Support
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="nft"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        NFT Innovation
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="defi"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        DeFi Development
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="frontiers"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        New Frontiers
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="advancement"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Zero-Knowledge Advancements
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="gaming"
                        onChange={handleCheckBoxChange}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Gaming on D-Chain
                      </label>
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Do you have any prior experience with blockchain
                      development?
                    </label>
                    <div class="input-group gap-3">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="experience" id="flexRadioDefault1" onChange={handleChange} />
                        <label class="form-check-label" for="flexCheckDefault1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="experience" id="flexRadioDefault2" onChange={handleChange} checked />
                        <label class="form-check-label" for="flexCheckChecked2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6 ">
                    <label for="basic-url" class="form-label">
                      Please provide a brief overview of your project idea for the
                      hackathon:
                    </label>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="say somthing"
                        id="floatingTextarea"
                        name="overview"
                        value={values.overview}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.overview && errors.overview ? true : false}
                      ></textarea>
                    </div>
                    {touched.overview && errors.overview && (
                      <small className="error">
                        {errors.overview}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 ">
                    <label for="basic-url" class="form-label">
                      What does your startup hope to achieve by participating in
                      the VISIONARY Hackathon 2024?
                    </label>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="say something"
                        id="floatingTextarea"
                        name="visionary"
                        value={values.visionary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.visionary && errors.visionary ? true : false}
                      ></textarea>
                    </div>
                    {touched.visionary && errors.visionary && (
                      <small className="error">
                        {errors.visionary}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4 className=" my-3">Additional Information</h4>
                  <div class="mb-3 col-12">
                    <label for="basic-url" class="form-label">
                      How did you hear about the VISIONARY Hackathon 2024?
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3 basic-addon4"
                        name="question1"
                        value={values.question1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.question1 && errors.question1 ? true : false}
                      />
                    </div>
                    {touched.question1 && errors.question1 && (
                      <small className="error">
                        {errors.question1}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Do you require any special assistance or resources from the
                      D-Ecosystem?
                    </label>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="say something"
                        id="floatingTextarea"
                        name="question2"
                        value={values.question2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.question2 && errors.question2 ? true : false}
                      ></textarea>
                    </div>
                    {touched.question2 && errors.question2 && (
                      <small className="error">
                        {errors.question2}
                      </small>
                    )}
                  </div>
                  <div class="mb-3 col-lg-6 col-md-6">
                    <label for="basic-url" class="form-label">
                      Any other information or questions you would like to share
                      or ask?
                    </label>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="say something"
                        id="floatingTextarea"
                        name="question3"
                        value={values.question3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.question3 && errors.question3 ? true : false}
                      ></textarea>
                    </div>
                    {touched.question3 && errors.question3 && (
                      <small className="error">
                        {errors.question3}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4 className=" my-3">Consent and Confirmation</h4>
                  <div class="mb-3 col-12 ">

                    <div class="input-group d-flex">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          name="confirmation1"
                          checked={values.confirmation1}
                          onChange={handleChange}
                        /> <label for="basic-url" class="form-label">
                          I confirm that all the information provided is accurate and
                          that I have the authority to register my startup for the
                          VISIONARY Hackathon 2024.
                        </label>
                      </div>
                      {touched.confirmation1 && errors.confirmation1 && (
                        <small className="error">
                          {errors.confirmation1}
                        </small>
                      )}
                    </div>

                  </div>
                  <div class="mb-3 col-12">
                    <div class="input-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          name="confirmation2"
                          checked={values.confirmation2}
                          onChange={handleChange}
                        />
                        <label for="basic-url" class="form-label">
                          I agree to the Terms & Conditions of the VISIONARY Hackathon
                          2024.
                        </label>
                      </div>
                    </div>
                    {touched.confirmation2 && errors.confirmation2 && (
                      <small className="error">
                        {errors.confirmation2}
                      </small>
                    )}
                  </div>
                </div>

                <div>
                  <button className="submit-button">Submit</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
