import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsArrowUpRightCircle } from 'react-icons/bs';
// import Blog1 from "../../Assests/images/blog/blog1.jpg"
// import Blog2 from "../../Assests/images/blog/blog2.jpg"
// import Blog3 from "../../Assests/images/blog/blog3.jpg"
// import Blog4 from "../../Assests/images/blog/blog4.jpg"
// import Blog5 from "../../Assests/images/blog/blog5.jpg"
// import Blog6 from "../../Assests/images/blog/blog6.jpg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";
// import blog from "../../Assests/images/banner/Blog/Blog.png"
import axios from "axios";
// import required modules
import { Navigation } from "swiper";
import { API_URL } from "../../config/config";

const Blog = () => {
    const [blogData, setBlogData] = useState([]);
    useEffect(() => {
        axios({
            method: "GET",
            url: `${API_URL}blogs/data`
        }).then((data) => {
            setBlogData(data.data)
        }).catch((err) => console.log(err))
    }, [blogData.length])
    return (
        <div>
            <div className="container" id="blog">
                <p className="banner-text-2 mb-5 text-start">Blog</p>
                <div class="row justify-content-center">
                    <div class="col">
                        <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper "
                            spaceBetween={80}
                            breakpoints={{
                                "@0.00": {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                "@0.75": {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                "@1.00": {
                                    slidesPerView: 1,
                                    spaceBetween: 40,
                                },
                                "@1.50": {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            {
                                blogData.map((blogItems) => {
                                    return (
                                        <SwiperSlide >
                                            <a href={"/blog/"+blogItems.blog_link} target="_blank">
                                                <div class="card h-100">
                                                    <img src={blogItems.blogimg} alt="" />
                                                    <div class="card-body">
                                                        {/* <div class="banner-content">
                                                            <a><BsArrowUpRightCircle /></a>
                                                        </div> */}
                                                        <div className="d-flex flex-row">
                                                            <div>
                                                                <span className="blog-text-1">{blogItems.date}</span>
                                                            </div>
                                                            {/* <div className="ms-auto">
                                                                <span className="blog-text-2">{blogItems.blog_category}</span>
                                                            </div> */}
                                                        </div>
                                                        <p class="card-text">{blogItems.blog_title}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Blog