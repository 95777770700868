import React from "react";


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";

// import required modules
import { Navigation } from "swiper";
import { BsArrowUpRightCircle } from 'react-icons/bs';
import axios from "axios";
import { API_URL } from "../config/config";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.myDivToFocus = React.createRef()
    this.state = {
      jobsData: [],
      errors: {}
    };
  }
  getJobsData = () => {
    axios({
      method: "GET",
      url: `${API_URL}jobs/data`
    }).then((data) => {
      this.setState({
        jobsData: data.data
      })
    })
  };
  componentDidMount = () => {
    this.getJobsData();

  };
  render() {
    return (
      <div className="App">
        {console.log("jobsData==========", this.state.jobsData)}
        <section className=" internal-page-padding-top pb-5">
          <div className="container">
            <p className="banner-text-2">Career</p>
            <div className="row row-cols-1 row-cols-lg-2 event-banner-section col-lg-12 mx-auto g-4">
              {
                (this.state.jobsData).map((job) => {
                  return (
                    <div className="col">
                      <div className="">
                        <div class="card h-100">
                          <div class="card-body">
                            <div className="row">
                              <div className="col-6">
                                <p>Job Title :</p>
                              </div>
                              <div className="col-6">
                                <p>{job.jobs_title}</p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <p>Job Description: </p>
                              </div>
                              <div className="col-6">
                                <p> {job.jobs_description} </p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <p>No of vacancy</p>
                              </div>
                              <div className="col-6">
                                <p>{job.jobs_no_vacancy}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-row justify-content-end mt-3">
                              <a href="https://mail.google.com/mail/u/0/?fs=1&to=info@d-ecosystem.io&su=REGUARDING%20JOBS&body=BODY&tf=cm" target="_blank">
                                <button type="button" className=" join-event-button">APPLY NOW</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })
              }


              {/* <div className="col">
                <div className="">
                  <div class="card h-100">
                    <div class="card-body">
                      <div className="d-flex flex-row">
                        <div>
                          <p>Job Title :</p>
                          <p>Job Description: </p>
                          <p>No of vacancy</p>

                        </div>
                        <div className="ms-auto text-end">
                          <p>Blockchain Developers</p>
                          <p>2-5 Experience in Blockchain Developers</p>
                          <p>2</p>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-end mt-3">
                        <button type="button" class=" join-event-button">Join Event </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}
            </div>
          </div>
        </section>





      </div>
    );
  }
}

export default App;
