import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,

} from "react-router-dom";
import Navbar from "./Pages/Common/Navbar";
import Footer from "./Pages/Common/Footer";
import Home from "./Pages/Home";
import Dchain from "./Pages/D-chain";
import Event from "./Pages/Event";
import Career from "./Pages/Career";
import Mediakit from "./Pages/Mediakit";
import Aboutus from "./Pages/Aboutus";
import Error404 from "./Pages/Error";
import Comingsoon from "./Pages/Comingsoon";
import Privacypolicy from "./Pages/Privacypolicy";
import Termsofuse from "./Pages/Termsofuse";
import Blog from "./Pages/Separate/Blogread";
import Dappkit from "./Pages/Dapp-kit";
import BlogRead from "./Pages/Separate/Blogread";
import Contactus from "./Pages/Separate/Contactus";
import Crew from "./Pages/Teamcrew/Bubble-top";
import Subscribe from "./Pages/Subscribe";
import Hackathon from "./Pages/Hackathon";
import Form from "./Pages/Form";
import './Assests/css/Style.css';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/d-chain" element={<Dchain />} />
          <Route exact path="/event" element={<Event />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/mediakit" element={<Mediakit />} />
          <Route exact path="/dapp-application-kit" element={<Dappkit />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/comingsoon" element={<Comingsoon />} />
          <Route exact path="/privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/termsofuse" element={<Termsofuse />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="/crew" element={<Crew />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/:link" element={<BlogRead />}/>
          <Route exact path="/subscribe" element={<Subscribe />} />
          <Route exact path="/hackathon" element={<Hackathon />} />
          <Route exact path="/hackathon-register" element={<Form />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
