import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Coordinator from "../../Assests/images/new-team/Coordinator.png"
import crew1 from "../../Assests/images/crew-member/Layer 12.png"
import crew3 from "../../Assests/images/crew-member/Layer 4.png"
import crew4 from "../../Assests/images/crew-member/Layer 5.png"
import crew5 from "../../Assests/images/crew-member/Layer 6.png"
import crew6 from "../../Assests/images/crew-member/Layer 1.png"
import crew7 from "../../Assests/images/crew-member/Layer 7.png"
import crew8 from "../../Assests/images/crew-member/Layer 8.png"
import crew9 from "../../Assests/images/crew-member/Layer 9.png"
import crew10 from "../../Assests/images/crew-member/Layer 10.png"
import crew11 from "../../Assests/images/crew-member/Layer 13.png"


HighchartsMore(Highcharts);




function Bubble(props) {



    let options = {
        chart: {
            type: 'packedbubble',
            height: '40%'
        },
        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            borderColor: 'none',
            useHTML: true,
            pointFormat: '<div class="crew-tooltip-section"><b class="dao-text-1">{point.name}</b><p class="dao-text-3 dao-text-3-margin">{point.role}</p><p class="dao-text-3">{point.desc}</p></div>'
        },
      

        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            packedbubble: {
                minSize: '30%',
                maxSize: '100%',
                zMin: 0,
                zMax: 600,
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.02
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}',
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: 0
                    },
                    style: {
                        color: 'white',
                        textOutline: 'none',
                        fontWeight: 'normal'
                    }
                }
            }
        },


        series: [{
            colorByPoint: true,
            point: {
                events: {
                    click: function() {
                        this.series.chart.update({
                            tooltip: {
                                enabled: true
                            }
                        });
                    },
                    mouseOut: function() {
                        this.series.chart.update({
                            tooltip: {
                                enabled: false
                            }
    
                        })
                    }
                }
            },
            name: 'Core Team',
            data: [{
                name: 'HENNING H.',
                role: "head platform develover",
                value: 467.1,
                marker: {
                    symbol: 'url(' + crew1 + ')'
                },
            }, {
                name: 'Fabian B.',
                role: "Blockchain Development",
                desc: "CEO SRT GmbH",
                value: 367.1,
                marker: {
                    symbol: 'url(' + crew3 + ')'
                },
            }, {
                name: 'CHANUNDA K.',
                role: "marketing strategy asia ",
                desc: "",
                value: 310.1,
                marker: {
                    symbol: 'url(' + crew4 + ')'
                },
            }, {
                name: 'ALI ANSARIKHALEDI',
                role: "business it solutions",
                desc: "",
                value: 280.1,
                marker: {
                    symbol: 'url(' + crew5 + ')'
                },
            }, {
                name: 'DR. SABINE ',
                role: "Experience of 20 Years",
                desc: "Foundraising & MARKETING",
                value: 250.1,
                marker: {
                    symbol: 'url(' + crew6 + ')'
                },
            }, {
                name: 'ALEXANDER K. ',
                role: "foundraising & marketing",
                desc: "20 years experience",
                value: 220.1,
                marker: {
                    symbol: 'url(' + crew7 + ')'
                },
            }, {
                name: 'MARIA G.',
                role: "communications manager HR &",
                desc: "organizational development",
                value: 180.1,
                marker: {
                    symbol: 'url(' + crew8 + ')'
                },
            }, {
                name: 'EVAN G.',
                role: "Co Founder & Advisor  ",
                desc: "International Business",
                value: 160.1,
                marker: {
                    symbol: 'url(' + crew9 + ')'
                },
            }, {
                name: 'Heinz Dieter W.',
                role: "Financial Advisor ",
                desc: "& Founder Acquisition and support",
                value: 120.1,
                marker: {
                    symbol: 'url(' + crew10 + ')'
                },
            }, {
                name: 'Dr. Christo B. ',
                role: "Advisor international",
                desc: "crypto community",
                value: 80.1,
                marker: {
                    symbol: 'url(' + crew11 + ')'
                },
            }]
        }]
    }
    return (
        <HighchartsReact
            className="HighchartsReact-banner"
            highcharts={Highcharts}
            options={options}
            immutable={true}
        />
    );
}

export default Bubble;
