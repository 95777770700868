import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Anna from "../../Assests/images/crew-member/Anna.png"
import Chanunda from "../../Assests/images/crew-member/Chanunda. K - Toon.png"
import Ines from "../../Assests/images/crew-member/Ines H - Toon.png"
import Kana from "../../Assests/images/crew-member/Kana k - Toon.png"
import PicArt from "../../Assests/images/crew-member/Pic Art Loren D - Toon.png"
import Sabine from "../../Assests/images/crew-member/Sabine - Toon.png"
import Renate from "../../Assests/images/crew-member/Renate. S - toon.png"
import Silvia from "../../Assests/images/crew-member/Silvia. J - Toon.png"
import Simone from "../../Assests/images/crew-member/Simone. N - Toon.png"
import SoSo from "../../Assests/images/crew-member/SoSo Graphik Design Julia L - Toon.png"
import Alexander from "../../Assests/images/crew-member/male/Alexander - Toon.png"
import Arnold from "../../Assests/images/crew-member/male/Arnold - Toon.png"
import Christian from "../../Assests/images/crew-member/male/Christian.R - Toon.png"
import Peter from "../../Assests/images/crew-member/male/Peter. G - Toon.png"
import Tomas from "../../Assests/images/crew-member/male/De4D Tomas.S - Toon.png"
import Dellmuth from "../../Assests/images/crew-member/male/Dellmuth - Toon.png"
import Dieter from "../../Assests/images/crew-member/male/Dieter.E - Toon.png"
import Josh from "../../Assests/images/crew-member/male/Dif Network Josh L - Toon.png"
import Panka from "../../Assests/images/crew-member/male/Digital Panka.L - Toon.png"
import Farzin from "../../Assests/images/crew-member/male/Farzin - Toon.png"
import Filipe from "../../Assests/images/crew-member/male/Filipe - Toon.png"
import Heinz from "../../Assests/images/crew-member/male/Heinz - Toon.png"
import Herold from "../../Assests/images/crew-member/male/Herold.F - Toon.png"
import ITDieter from "../../Assests/images/crew-member/male/IT World Dieter - Toon.png"
import Salau from "../../Assests/images/crew-member/male/Jimmy Salau - Toon.png"
import Manuel from "../../Assests/images/crew-member/male/Manuel - Toon.png"
import Patrick from "../../Assests/images/crew-member/male/Patrick.L - Toon.png"
import PaulW from "../../Assests/images/crew-member/male/Paul.W - Toon.png"
import Raphael from "../../Assests/images/crew-member/male/Raphael.G - Toon.png"
import Stefan from "../../Assests/images/crew-member/male/Stefan - Toon.png"
import Stephen from "../../Assests/images/crew-member/male/Stephen.D - Toon.png"
import Teamek from "../../Assests/images/crew-member/male/Teamek Julien.B.png"
import NetworkPeter from "../../Assests/images/crew-member/male/Coinworld Network Peter - Toon.png"
import Jaana from "../../Assests/images/crew-member/jaana.png"


HighchartsMore(Highcharts);




function Bubble(props) {



    let options = {
        chart: {
            type: 'packedbubble',
            height: '60%'
        },
        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            borderColor: 'none',
            useHTML: true,
            pointFormat: '<div class="crew-tooltip-section"><b class="dao-text-1">{point.name}</b><p class="dao-text-3 dao-text-3-margin">{point.role}</p><p class="dao-text-3">{point.desc}</p></div>'
        },


        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            packedbubble: {
                minSize: '30%',
                maxSize: '100%',
                zMin: 0,
                zMax: 600,
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.02
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}',
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: 0
                    },
                    style: {
                        color: 'white',
                        textOutline: 'none',
                        fontWeight: 'normal'
                    }
                }
            }
        },


        series: [{
        colorByPoint: true,
        // point: {
        //     events: {
        //         click: function () {
        //             this.series.chart.update({
        //                 tooltip: {
        //                     enabled: true
        //                 }
        //             });
        //         },
        //         mouseOut: function () {
        //             this.series.chart.update({
        //                 tooltip: {
        //                     enabled: false
        //                 }

        //             })
        //         }
        //     }
        // },
            className: "graph-red",
            name: 'Teams',
            color: 'rgba(27, 159, 254, 0.3)',
            fillColor: 'yellow',
            data: [{
                name: 'Stefan R.',
                role: 'DAO Founder Marketing & Sales',
                value: 199.4,
                marker: {
                    symbol: 'url(' + Stefan + ')'
                },
            },
            {
                name: 'Silvia  J.',
                role: 'Sales coordination',
                value: 194.4,
                marker: {
                    symbol: 'url(' + Silvia + ')'
                },
            },
            {
                name: 'Manuel S.',
                role: 'Sales Customer Service',
                value: 189.4,
                marker: {
                    symbol: 'url(' + Manuel + ')'
                },
            },
            {
                name: 'Christian R.',
                role: 'DAO Founder &sales Manager',
                value: 185.4,
                marker: {
                    symbol: 'url(' + Christian + ')'
                },
            },
            {
                name: 'Farzin S.',
                role: 'DAO Founder IT Advisor & Consultant',
                value: 180.4,
                marker: {
                    symbol: 'url(' + Farzin + ')'
                },
            },
            {
                name: 'Alexander K.',
                role: 'DAO Founder Marketing & Sales Advisor',
                value: 179.4,
                marker: {
                    symbol: 'url(' + Alexander + ')'
                },
            },
            {
                name: 'Herold F.',
                role: 'DAO Founder Marketing Advisor',
                value: 175.4,
                marker: {
                    symbol: 'url(' + Herold + ')'
                },
            },
            {
                name: 'Pic Art Loren D.',
                role: 'DAO Founder Design & Graphic',
                value: 172.4,
                marker: {
                    symbol: 'url(' + PicArt + ')'
                },
            },
            {
                name: 'De4D Tomas S.',
                role: 'DAO Founder Public Relations',
                value: 168.4,
                marker: {
                    symbol: 'url(' + Tomas + ')'
                },
            },
            {
                name: 'Jaana G.',
                role: 'Public Relations',
                value: 162.4,
                marker: {
                    symbol: 'url(' + Jaana + ')'
                },
            },
            {
                name: 'Renate S.',
                role: 'DAO Founder Marketing',
                value: 156.4,
                marker: {
                    symbol: 'url(' + Renate + ')'
                },
            },
            {
                name: 'Dieter E.',
                role: 'DAO Founder Advisor HR Management',
                value: 150.4,
                marker: {
                    symbol: 'url(' + Dieter + ')'
                },
            },
            {
                name: 'Peter G.',
                role: 'DAO Founder Advisor IT',
                value: 144.4,
                marker: {
                    symbol: 'url(' + Peter + ')'
                },
            },
            {
                name: 'Stephen D.',
                role: 'DAO Foundercommunication admin',
                value: 199.4,
                marker: {
                    symbol: 'url(' + Stephen + ')'
                },
            },
            {
                name: 'Simone N.',
                role: 'DAO Founder Research Admin',
                value: 140.4,
                marker: {
                    symbol: 'url(' + Simone + ')'
                },
            },
            {
                name: 'Ines H.',
                role: 'DAO Founder Sales & Marketing',
                value: 136.4,
                marker: {
                    symbol: 'url(' + Ines + ')'
                },
            },
            {
                name: 'Heinz W.',
                role: 'DAO Founder Sales &financial advisor',
                value: 130.4,
                marker: {
                    symbol: 'url(' + Heinz + ')'
                },
            },
            {
                name: 'IT World Dieter M.',
                role: 'DAO Founder Advisor Web Design',
                value: 126.4,
                marker: {
                    symbol: 'url(' + ITDieter + ')'
                },
                
            },
            {
                name: 'Dellmuth  X.',
                role: 'DAO Founder Communication Manager',
                value: 122.4,
                marker: {
                    symbol: 'url(' + Dellmuth + ')'
                },
            },
            {
                name: 'Sabine E.',
                role: 'DAO Founder Sales @ Marketing Advisor',
                value: 120.4,
                marker: {
                    symbol: 'url(' + Sabine + ')'
                },
            },
            {
                name: 'SoSo Graphik <br/> Design Julia L.',
                role: 'DAO Founder, Design & Graphik',
                value: 184.4,
                marker: {
                    symbol: 'url(' + SoSo + ')'
                },
            },
            {
                name: 'Filipe E.',
                role: 'DAO Founder  Marketing Strategy',
                value: 108.4,
                marker: {
                    symbol: 'url(' + Filipe + ')'
                },
            },
            {
                name: 'Anna P.',
                role: 'DAO Founder International Communication',
                value: 102.4,
                marker: {
                    symbol: 'url(' + Anna + ')'
                },
            },
            {
                name: 'Kana K.',
                role: 'DAO Founder Community Manager',
                value: 95.4,
                marker: {
                    symbol: 'url(' + Kana + ')'
                },
            },
            {
                name: 'Teamek Julien B.',
                role: 'DAO Founder Team coordination',
                value: 90.4,
                marker: {
                    symbol: 'url(' + Teamek + ')'
                },
            },
            {
                name: 'Patrick L.',
                role: 'DAO Founder Sales & Customer Service',
                value: 84.4,
                marker: {
                    symbol: 'url(' + Patrick + ')'
                },
            },
            {
                name: 'Jimmy Salau',
                role: 'DAO Founder Social Media Management',
                value: 80.4,
                marker: {
                    symbol: 'url(' + Salau + ')'
                },
            },
            {
                name: 'Dlf Network<br/> Josh L.',
                role: 'DAO Founder Social Media Management',
                value: 76.4,
                marker: {
                    symbol: 'url(' + Josh + ')'
                },
            },
            {
                name: 'Digital Panka L.',
                role: 'Social Media Management',
                value: 72.4,
                marker: {
                    symbol: 'url(' + Panka + ')'
                },
            },
            {
                name: 'Chanunda K.',
                role: 'DAO Founder Marketing Asi Advisor',
                value: 50.4,
                marker: {
                    symbol: 'url(' + Chanunda + ')'
                },
            },
            {
                name: 'Coinworld <br/> Network Peter N.',
                role: 'DAO Founder Digital Assets Advisor',
                value: 448.4,
                marker: {
                    symbol: 'url(' + NetworkPeter + ')'
                },
            },
            {
                name: 'Arnold S.',
                role: 'DAO Founder Financial Advisor',
                value: 20.4,
                marker: {
                    symbol: 'url(' + Arnold + ')'
                },
            },
            {
                name: 'Paul W.',
                role: 'DAO Founder Sales & Marketing',
                value: 12.4,
                marker: {
                    symbol: 'url(' + PaulW + ')'
                },
            },
            {
                name: 'Raphael G.',
                role: 'DAO FounderApp design and creation',
                value: 6.4,
                marker: {
                    symbol: 'url(' + Raphael + ')'
                },
            }
        ]
        }]
    }
    return (
        <HighchartsReact
            className="HighchartsReact-banner"
            highcharts={Highcharts}
            options={options}
            immutable={true}
        />
    );
}

export default Bubble;
