import React, { useEffect, useState } from "react";
import { BsArrowUpRight, BsArrowRight, BsArrowUpRightCircle } from 'react-icons/bs';
import validator from "../../Assests/images/banner/icons/validator.png"
import testnet from "../../Assests/images/banner/icons/testnet.png"
import mainnet from "../../Assests/images/banner/icons/mainnet.png"


class Hometabs extends React.Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div className="container position-relative banner-section-list-tabs">
                    <div class="css-border-link"></div>
                    <div class="row row-cols-1 row-cols-lg-3 justify-content-center text-center g-4">
                        <div class="col">
                            <div class="stepImg-tabs">
                                <img loading="lazy" src={validator} alt="flip-image" />
                            </div>
                            <h5 className="stepImge-text-1">Main Net</h5>
                            <p className="stepImge-text-2">The live and operational version of the D-Chain blockchain. It is the main network that users and developers interact with to conduct transactions and build decentralized applications (DApps).</p>
                            <a href="https://mainnet-explorer.d-ecosystem.io/" target="_blank" className="banner-button-2-2 mt-4" type="submit">Explore with Explorer <BsArrowRight className="banner-button-icon-1" /></a>

                        </div>
                        <div class="col">
                            <div class="stepImg-tabs">
                                <img loading="lazy" src={testnet} alt="flip-image" />
                            </div>
                            <h5 className="stepImge-text-1">Test Net</h5>
                            <p className="stepImge-text-2">D-Chain can be used by developers to test smart contracts, decentralized applications, and other blockchain-related services. It can also be used by users to experiment with different features and to learn more about how the blockchain works.</p>
                            <a href="https://testnet-explorer.d-ecosystem.io/" target="_blank" className="banner-button-2-2 mt-3" type="submit">Explore with Explorer <BsArrowRight className="banner-button-icon-1" /></a>
                        </div>
                        <div class="col">
                            <div class="stepImg-tabs">
                                <img loading="lazy" src={mainnet} alt="flip-image" />
                            </div>
                            <h5 className="stepImge-text-1">Validator</h5>
                            <p className="stepImge-text-2">Validator is a participant in the network who is responsible for verifying and validating transactions and adding them to the blockchain. Validators play a critical role in ensuring the security, transparency, and integrity of the blockchain network.</p>
                            <a href="https://validator.d-ecosystem.io/" target='_blank' className="banner-button-2-2 mt-3" type="submit">Become Validator <BsArrowRight className="banner-button-icon-1" /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Hometabs;