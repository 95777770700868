import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

export default function Pie(props) {
    useEffect(() => {
        $(document).ready(function () {
            $('g:has(> g[stroke="#3cabff"])').hide();

        });


        var chart = am4core.create("piediv", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.legend = new am4charts.Legend();

        chart.data = [
            {
                country: "Ecosystem Development",
                litres: 22.00
            },
            {
                country: "Advisors",
                litres: 4.30
            },
            {
                country: "Presale",
                litres: 15.70
            },
            {
                country: "Team",
                litres: 10.00
            },
            {
                country: "Foundation",
                litres: 5.00
            },
            {
                country: "Stacking Rewards",
                litres: 10.00
            },
            {
                country: "Swap Liquidity",
                litres: 32.24
            },
            {
                country: "Public Sales",
                litres: 0.76
            }
          
        ];

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "litres";
        series.dataFields.category = "country";

        return () => {
            chart && chart.dispose();
        };
    });

    return (
        <div
            id="piediv"
            className={props.className}
            style={{ width: "100%", height: "500px" }}
        ></div>
    );
}
