import React from "react";


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";

import favicon16X16 from "../Assests/images/mediakit/16X16.ico"
import favicon32X32 from "../Assests/images/mediakit/32X32.ico"
import favicon96X96 from "../Assests/images/mediakit/96X96.ico"
import favicon180X180 from "../Assests/images/mediakit/180X180.ico"

import logo16X16 from "../Assests/images/mediakit/16X16 (1).png"
import logo32X32 from "../Assests/images/mediakit/32X32 (1).png"
import logo96X96 from "../Assests/images/mediakit/96X96 (1).png"
import logo180X180 from "../Assests/images/mediakit/180X180 (1).png"

import { BsThreeDotsVertical } from 'react-icons/bs';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.myDivToFocus = React.createRef()
    this.state = {

      errors: {}
    };
  }
  render() {
    return (
      <div className="App">



        <section className=" internal-page-padding-top pb-5">
          <div className="container">
            <p className="banner-text-2 text-start mb-4">Media Kit</p>
            <div className="row">
              <div className="col">
                <h5>Favicon</h5>
                <div className="row row-cols-lg-6 row-cols-12 col-lg-8 mediakit-top-section">
                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="favicon16X16" href={favicon16X16} title="favicon16X16">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={favicon16X16} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="favicon32X32" href={favicon32X32} title="favicon32X32">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={favicon32X32} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="favicon96X96" href={favicon96X96} title="favicon96X96">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={favicon96X96} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="favicon180X180" href={favicon180X180} title="favicon180X180">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={favicon180X180} alt="" />
                    </div>
                  </div>


                </div>


                <h5 className="mt-5">Logo</h5>
                <div className="row row-cols-lg-6 row-cols-12 col-lg-8 mediakit-top-section">
                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="logo16X16" href={logo16X16} title="logo16X16">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={logo16X16} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="logo32X32" href={logo32X32} title="logo32X32">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={logo32X32} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="logo96X96" href={logo96X96} title="logo96X96">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={logo96X96} alt="" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="card">
                      <div className="d-flex flex-row ms-auto">
                        <div>
                          <div class="dropdown">
                            <button class="bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <BsThreeDotsVertical className="text-white" />
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item fs-12">
                                  <a download="logo180X180" href={logo180X180} title="logo180X180">
                                    Download
                                  </a>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                      <img src={logo180X180} alt="" />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>





      </div>
    );
  }
}

export default App;
