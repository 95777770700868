import React from 'react'
import { useForm } from 'react-hook-form'

import video from '../Assests/images/banner/2.mp4'
import Ecosystem from '../Assests/images/video/All D-Ecosystem-2.mp4'
import SwapAndNft from '../Assests/images/video/Swap And Nft-1.mp4'
import Ido from '../Assests/images/video/Ido-1.m4v'
import Airdrop from '../Assests/images/video/Airdrop-1.mp4'
import Wallet from '../Assests/images/video/Wallet-1.mp4'

import Protocol from '../Assests/images/video/Protocol.gif'
import {
  BsArrowUpRight,
  BsArrowRight,
  BsArrowUpRightCircle,
} from 'react-icons/bs'
import { AiTwotoneBuild } from 'react-icons/ai'
import { FaChevronRight, FaCubes } from 'react-icons/fa'
import { SiQuantconnect, SiDatabricks } from 'react-icons/si'
import gif1 from '../Assests/images/banner/gif.gif'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import glow2 from '../Assests/images/banner/glow/1.png'
import gif2 from '../Assests/images/banner/icons/1.gif'
import gif3 from '../Assests/images/banner/icons/2.gif'
import gif4 from '../Assests/images/banner/icons/3.gif'

import build from '../Assests/images/banner/icons/build.png'
import build2 from '../Assests/images/banner/icons/build-2.png'
import build3 from '../Assests/images/banner/icons/build-3.png'
import buildicon1 from '../Assests/images/banner/icons/icon-1.png'
import buildicon2 from '../Assests/images/banner/icons/icon-2.png'
import buildicon3 from '../Assests/images/banner/icons/icon-3.png'

import Benzinga from '../Assests/images/featured/Benzinga.png'
import Marketwatch from '../Assests/images/featured/Marketwatch.png'
import dailyHodl from '../Assests/images/featured/daily-Hodl.png'
import digitaljournal from '../Assests/images/featured/digitaljournal.png'

import { BsArrowUpRightCircleFill } from 'react-icons/bs'

import box from '../Assests/images/banner/box.mp4'
import coinomicsdesktop from '../Assests/images/desktop.png'
import coinomicsmobile from '../Assests/images/mobile.png'
import codex from '../Assests/images/logo-listing/codex.png'
import coinwatch from '../Assests/images/logo-listing/coin-watch.png'
import sniper from '../Assests/images/logo-listing/sniper.png'
import cyberscope from '../Assests/images/logo-listing/cyberscope.png'
import onramper from '../Assests/images/logo-listing/onramper.png'
import transak from '../Assests/images/logo-listing/transak.png'
import Wyre from '../Assests/images/logo-listing/Wyre.png'
import xanpool from '../Assests/images/logo-listing/xanpool.png'
import kreft from '../Assests/images/advisor/kreft.png'
//import Pug from '../Assests/images/advisor/pug.png'
import Srt from '../Assests/images/advisor/srt.png'
import eg from '../Assests/images/advisor/banner.webp'

import arbutrum from '../Assests/images/Compatible-Networks/arbutrum.jpg'
import ethereum from '../Assests/images/Compatible-Networks/ethereum.jpg'
import fantom from '../Assests/images/Compatible-Networks/fantom.jpg'
import avalanche from '../Assests/images/Compatible-Networks/avalanche.jpg'
import binancesmartchain from '../Assests/images/Compatible-Networks/binance smartchain.jpg'
import polygon from '../Assests/images/Compatible-Networks/polygon.jpg'
import optimism from '../Assests/images/Compatible-Networks/optimism.jpg'
import celo from '../Assests/images/Compatible-Networks/celo.jpg'
import moonbeam from '../Assests/images/Compatible-Networks/moonbeam.jpg'

import dwalletmade from '../Assests/images/made-by-ecosystem/d-wallet.png'
import defi from '../Assests/images/made-by-ecosystem/defi.png'
import dnftmade from '../Assests/images/made-by-ecosystem/d-nft.png'
import dswapmade from '../Assests/images/made-by-ecosystem/d-swap.png'
import Tyrion from '../Assests/images/made-by-ecosystem/Tyrion.png'
import toptoken from '../Assests/images/made-by-ecosystem/top-token.png'
import mixedrarity from '../Assests/images/made-by-ecosystem/mixed-rarity.png'
import dechainmade from '../Assests/images/made-by-ecosystem/d-e-chain.png'
import dexchangemade from '../Assests/images/made-by-ecosystem/d-exchange.png'
import validator from '../Assests/images/made-by-ecosystem/validator.png'
import dusd from '../Assests/images/made-by-ecosystem/dusd.png'
import easymade from '../Assests/images/made-by-ecosystem/easy.png'
import minimade from '../Assests/images/made-by-ecosystem/mini.png'
import kycdeco from '../Assests/images/kyc-d-eco.png'
import kycdeco1 from '../Assests/images/kyc-d-eco.jpg'


import Tinqfinance from '../Assests/images/made-by-ecosystem/Tinq-finance.png'
import EonianFinance from '../Assests/images/made-by-ecosystem/Eonian-Finance.png'

import DAOStarter from '../Assests/images/Links/DAOStarter.png'
import KDG from '../Assests/images/Links/KDG.svg'
import Gagarin from '../Assests/images/Links/Gagarin.svg'
import DAOLaunch from '../Assests/images/Links/DAOLaunch.png'

import roundcircles from '../Assests/images/banner/coming soon/round-circles.png'

import sushilogo from '../Assests/images/banner/coming soon/sushi.png'
import pancakelogo from '../Assests/images/banner/coming soon/pancake.png'
import unicodelogo from '../Assests/images/banner/coming soon/unicode.png'
import oneinchlogo from '../Assests/images/banner/coming soon/1inch.png'

import Marquee from 'react-fast-marquee'
// import idolive from "../Assests/images/ido-launch-button-2.png"

import idolive from '../Assests/images/ido-launch-button-2.png'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-cards'

// import required modules
import { Autoplay, Navigation } from 'swiper'
import Coinomics from './Separate/Coinomics'
import Roadmap from './Separate/Roadmap'
import Blog from './Separate/Blog'
import Hometabs from './Separate/Home-tabs'
import Logo from '../Assests/images/main-logo.png'
import axios from 'axios' // axios import here
import { API_URL } from '../config/config'
import Swal from 'sweetalert2'
import $ from 'jquery'

import Coinstore from '../Assests/images/new-img/Coinstore.png'
import Dextrade from '../Assests/images/new-img/dex-trade.png'
import Coinsbit from '../Assests/images/new-img/Coinsbit.png'
import Cuptoken from '../Assests/images/new-img/cuptoken.png'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.myDivToFocus = React.createRef()
    this.state = {
      email: '',
      emailError: '',
      errors: {},
    }
  }

  componentDidMount() {
    $('.ethereum-anchor-link').click(function () {
      window.location = 'https://ethereum.org/'
    })
    $('.fantom-anchor-link').click(function () {
      window.location = 'https://fantom.foundation/'
    })
    $('.avalanche-anchor-link').click(function () {
      window.location = 'https://www.avax.network/'
    })
    $('.binancesmartchain-anchor-link').click(function () {
      window.location = 'https://www.bnbchain.org/en/smartChain'
    })
    $('.polygon-anchor-link').click(function () {
      window.location = 'https://polygon.technology/'
    })
    $('.arbutrum-anchor-link').click(function () {
      window.location = 'https://arbitrum.io/'
    })
    $('.optimism-anchor-link').click(function () {
      window.location = 'https://www.optimism.io/'
    })
    $('.celo-anchor-link').click(function () {
      window.location = 'https://celo.org/'
    })
    $('.moonbeam-anchor-link').click(function () {
      window.location = 'https://moonbeam.network/'
    })
  }

  // email handle change functions and API integration
  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    })
    console.log('event.target.value============', event.target.value)

    this.validationcheck(event.target.value)
  }

  validationcheck = (value) => {
    let isValid = true
    let emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
    // let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    console.log('value============', value)
    if (value == '') {
      this.setState({
        emailError: 'Email field is required',
      })
      isValid = false
    } else if (this.state.email == '' || this.state.email === undefined) {
      this.setState({
        emailError: 'Email field is required',
      })
      isValid = false
    } else if (!emailReg.test(this.state.email)) {
      this.setState({
        emailError: 'Email Address does not appear to be valid',
      })
      isValid = false
    }
    // else if (!emailReg.test(value)){
    //   this.setState({ emailError: "" });
    // }
    else {
      this.setState({ emailError: '' })
    }
    return isValid
  }

  handleSubmit = async (event) => {
    console.log('event========', event)
    event.preventDefault()
    var validstatus = this.validationcheck()
    console.log('validstatus=========', validstatus)
    if (validstatus) {
      axios({
        method: 'POST',
        url: `${API_URL}newsletter/add`,
        data: {
          email: this.state.email,
        },
      })
        .then((data) => {
          if (data.data) {
            this.setState({ email: '' })
          }
          Swal.fire({
            // title: "Wow!",
            text: 'Thanks For Joining our Community!!',
            icon: 'success',
          })
        })
        .catch((err) => console.log(err))
    }
  }

  render() {
    return (
      <div className="App">
        <section className="banner-section-1">
          <div className="container-fluid">
            <div className="row min-vh-100">
              <div className="col p-0">
                <Swiper
                  navigation={true}
                  autoplay={{
                    delay: 9000,
                  }}
                  loop
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                  spaceBetween={30}
                >

                  {/* <SwiperSlide className="position-relative">
                    <video className="banner-video-1" autoPlay loop muted>
                      <source src={Ecosystem} type="video/mp4" />
                    </video>
                    <div className="banner-section-8 container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            <h1 className="banner-text-1">
                              D-Ecosystem - Connecting The World to Crypto
                            </h1>
                            <div className="container">
                              <div class="row">
                                <div class="col-lg-7 e-mail-ad">
                                  <form onSubmit={this.handleSubmit} autoComplete='off'>
                                    <div className="deco d-flex flex-row align-items-center justify-content-center">
                                      <div class="input-group ">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Please enter your E-mail address"
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          name="email"
                                          value={this.state.email}
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                      <div className='mx-3'>
                                        <button type="submit" className="wait-button">
                                          Join the Waitlist
                                        </button>
                                      </div>
                                    </div>
                                    <span className="" style={{ color: 'red' }}>
                                      {this.state.emailError}
                                    </span>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide> */}

                  <SwiperSlide className="position-relative">
                    <video className="banner-video-1" autoPlay loop muted>
                      <source src={Ecosystem} type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            {/* <img src={idolive} className="idolive-button-image" alt="idolive" /> */}
                            <p className="banner-text-1">
                              D-Ecosystem - Connecting The World to Crypto
                            </p>
                            <div className="d-flex flex-row align-items-center">
                              <div class="container-glow">
                                <div class="btn-kyc-banner">
                                  <a
                                    href="https://sales.d-ecosystem.io/"
                                    target="_blank"
                                  >
                                    Get D-Coin
                                  </a>
                                </div>
                              </div>
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLScKbz_Eu_48CEIY6Sfu-dnuq1zeBNMTv0Pz-wq8FDbmnDn-zw/viewform"
                                target="_blank"
                                type="button"
                                className="banner-button-1"
                              >
                                <div>Apply for Partner</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide className=''>
                    <video className='banner-video-1' autoPlay loop muted>
                      <source src={SwapAndNft} type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            <img src={idolive} className="idolive-button-image" alt="idolive" />
                            <p className="banner-text-1">Buy, sell, and explorer exclusive digital items.</p>
                            <a href="https://sales.d-ecosystem.io/" target="_blank" type="button" className="banner-button-1"><div>Get D-Coin
                            </div></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide> */}
                  <SwiperSlide className="">
                    <video className="banner-video-1" autoPlay loop muted>
                      <source src={Ido} type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            {/* <img src={idolive} className="idolive-button-image" alt="idolive" /> */}
                            <p className="banner-text-1">
                              Secure, Scalable Blockchain platform for dApps
                            </p>
                            <div className="d-flex flex-row align-items-center">
                              <div class="container-glow">
                                <div class="btn-kyc-banner">
                                  <a
                                    href="https://sales.d-ecosystem.io/"
                                    target="_blank"
                                  >
                                    Get D-Coin
                                  </a>
                                </div>
                              </div>
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLScKbz_Eu_48CEIY6Sfu-dnuq1zeBNMTv0Pz-wq8FDbmnDn-zw/viewform"
                                target="_blank"
                                type="button"
                                className="banner-button-1"
                              >
                                <div>Apply for Partner</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="">
                    <video className="banner-video-1" autoPlay loop muted>
                      <source src={Airdrop} type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            {/* <img src={idolive} className="idolive-button-image" alt="idolive" /> */}
                            <p className="banner-text-1">
                              Get an Exciting Coin Airdrop to Your Address
                            </p>
                            <div className="d-flex flex-row align-items-center">
                              <div class="container-glow">
                                <div class="btn-kyc-banner">
                                  <a
                                    href="https://sales.d-ecosystem.io/"
                                    target="_blank"
                                  >
                                    Get D-Coin
                                  </a>
                                </div>
                              </div>
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLScKbz_Eu_48CEIY6Sfu-dnuq1zeBNMTv0Pz-wq8FDbmnDn-zw/viewform"
                                target="_blank"
                                type="button"
                                className="banner-button-1"
                              >
                                <div>Apply for Partner</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="">
                    <video className="banner-video-1" autoPlay loop muted>
                      <source src={Wallet} type="video/mp4" />
                    </video>
                    <div className="container">
                      <div className="row min-vh-100">
                        <div className="col">
                          <div className="banner-section-top-padding">
                            {/* <img src={idolive} className="idolive-button-image" alt="idolive" /> */}
                            <p className="banner-text-1">
                              A Crypto Wallet & Gateway to dApp
                            </p>
                            <div className="d-flex flex-row align-items-center">
                              <div class="container-glow">
                                <div class="btn-kyc-banner">
                                  <a
                                    href="https://sales.d-ecosystem.io/"
                                    target="_blank"
                                  >
                                    Get D-Coin
                                  </a>
                                </div>
                              </div>
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLScKbz_Eu_48CEIY6Sfu-dnuq1zeBNMTv0Pz-wq8FDbmnDn-zw/viewform"
                                target="_blank"
                                type="button"
                                className="banner-button-1"
                              >
                                <div>Apply for Partner</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 Partneradvisor-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5"> Buy and Trade DCX in Partner Exchanges</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
              <div class="col">
                <div class="card">
                  <a href="https://www.coinstore.com/spot/DCXUSDT#/spot/DCXUSDT" target="_blank">
                    <img src={Coinstore} alt="Coinstore" style={{filter:"brightness(0) invert(1)"}} />
                    <h6 className='fw-bold'>Buy on Coinstore</h6>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://dex-trade.com/spot/trading/DCXUSDT?interface=classic" target="_blank">
                    <img src={Dextrade} alt="Dextrade" style={{filter:"brightness(0) invert(1)"}} />
                    <h6 className='fw-bold'>Buy on Dex Trade</h6>
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="/comingsoon" target="_blank">
                    <img src={Coinsbit} alt="Coinsbit" />
                    <h6 className='fw-bold'>Buy on Coinsbit</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 Partneradvisor-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5"> DUSD our Gold Baked Stablecoin buy and trade at  Partner Exchange</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
              <div class="col">
                <div class="card">
                  <a href="https://dex-trade.com/spot/trading/DCXUSDT?interface=classic" target="_blank">
                    <img src={Dextrade} alt="Dextrade" style={{filter:"brightness(0) invert(1)"}} />
                    <h6 className='fw-bold'>Buy on Dex Trade</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 Partneradvisor-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5"> CUP Token, the RWA /Real  World Asset token, from our Partner Dictys Holding Ltd</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
              <div class="col">
                <div class="card">
                  <a href="https://presale.cuptoken.trade/" target="_blank">
                    <img src={Cuptoken} alt="Cuptoken" />
                    <h6 className='fw-bold mt-2'>CUP Token Presale</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="py-5 Partner-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5">
            BUY AND TRADE D-COIN/DCX in our Wallet APP
            </h1>

            <div class="row row-cols-2 row-cols-lg-4 g-4 col-lg-10 mx-auto">
              <div class="col">
                <div class="card">
                  <a href="https://www.onramper.com/" target="_blank">
                    <img src={onramper} alt="onramper" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://transak.com/" target="_blank">
                    <img src={transak} alt="transak" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://www.sendwyre.com/" target="_blank">
                    <img src={Wyre} alt="Wyre" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://xanpool.com/" target="_blank">
                    <img src={xanpool} alt="xanpool" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* <section className="banner-section-2 pb-5">
          <div className="container">
            <h1 className="banner-text-2">D-Ecosystem - A community-driven<br /> open-source Blockchain Protocol
            </h1>
            <div className="">
              <p className="banner-text-3">Powered by D-Chain</p>
            </div>
            <div class="row row-cols-1 row-cols-lg-3 g-4 mt-lg-5 mt-2">
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <AiTwotoneBuild className="banner-section-2-icon-1" />
                    <h5 class="card-title">Build</h5>
                    <p class="card-text">D-Chain's smart contract technology is designed to be fast, efficient, and secure, making it an ideal platform for building decentralized applications.</p>
                    <a href="https://mainnet-explorer.d-ecosystem.io/" target="_blank">Build Now<FaChevronRight className="ms-2" style={{ marginBottom: "2px" }} /></a>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <FaCubes className="banner-section-2-icon-1" />
                    <h5 class="card-title">Explore</h5>
                    <p class="card-text">D-Chain is a blockchain platform that offers a range of innovative features and benefits. It is a hard fork of Ethereum and uses a Proof of Stake (PoS) consensus algorithm, which makes it more energy-efficient and faster than other blockchain platforms</p>
                    <a href="https://mainnet-explorer.d-ecosystem.io/eth-rpc-api-docs" target="_blank">Explore More<FaChevronRight className="ms-2" style={{ marginBottom: "2px" }} /></a>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <SiQuantconnect className="banner-section-2-icon-1" />
                    <h5 class="card-title">Connect</h5>
                    <p class="card-text">Grow your network and most other builders on D-Ecosystem by joining our Open source organisation and Open Source Protocol.</p>
                    <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer">Contribute<FaChevronRight className="ms-2" style={{ marginBottom: "2px" }} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="banner-section-2-2 py-5">
          <div className="container">
            <h1 className="banner-text-2">
              D-Ecosystem - A community-driven
              <br /> open-source Blockchain Protocol
            </h1>
            <div className="">
              <p className="banner-text-3">Powered by D-Chain</p>
            </div>

            <div class="row row-cols-1 row-cols-lg-3 g-4 mt-lg-3 mt-2 align-items-center justify-content-center flex-lg-row-reverse">
              <div className="col-lg-6">
                {/* <video autoPlay loop muted className="Protocol-video-section">
                      <source src={Protocol}  type="video/mp4" />
                    </video> */}
                <img src={Protocol} alt="Protocol" />
                {/* <div className="row build-flip-image-section text-center">
                  <div className="col-6">
                    <img className="build-flip-image-1" src={build2} alt="build" />
                  </div>
                  <div className="col-6">
                    <img className="build-flip-image-1" src={build3} alt="build" />
                  </div>
                  <div className="col-12 text-center">
                    <img className="build-flip-image-2" src={build} alt="build" />
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5">
                <div class="row row-cols-1 row-cols-lg-1 g-4 ">
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-body">
                        <div className="d-flex flex-row gap-3">
                          <div>
                            <img
                              className="build-flip-icon-1"
                              src={buildicon1}
                              alt="build"
                            />
                          </div>
                          <div>
                            <h5 class="card-title">Build</h5>
                            <p class="card-text">
                              D-Chain's smart contract technology is designed to
                              be fast, efficient, and secure, making it an ideal
                              platform for building decentralized applications.
                            </p>
                            <a
                              href="https://mainnet-explorer.d-ecosystem.io/"
                              target="_blank"
                            >
                              Build Now
                              <FaChevronRight
                                className="ms-2"
                                style={{ marginBottom: '2px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card h-100">
                      <div class="card-body">
                        <div className="d-flex flex-row gap-3">
                          <div>
                            <img
                              className="build-flip-icon-1"
                              src={buildicon2}
                              alt="build"
                            />
                          </div>
                          <div>
                            <h5 class="card-title">Explore</h5>
                            <p class="card-text">
                              D-Chain is a blockchain platform that offers a
                              range of innovative features and benefits. It is a
                              hard fork of Ethereum and uses a Proof of Stake
                              (PoS) consensus algorithm, which makes it more
                              energy-efficient and faster than other blockchain
                              platforms
                            </p>
                            <a
                              href="https://mainnet-explorer.d-ecosystem.io/eth-rpc-api-docs"
                              target="_blank"
                            >
                              Explore More
                              <FaChevronRight
                                className="ms-2"
                                style={{ marginBottom: '2px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card h-100">
                      <div class="card-body">
                        <div className="d-flex flex-row gap-3">
                          <div>
                            <img
                              className="build-flip-icon-1"
                              src={buildicon3}
                              alt="build"
                            />
                          </div>
                          <div>
                            <h5 class="card-title">Connect</h5>
                            <p class="card-text">
                              Grow your network and most other builders on
                              D-Ecosystem by joining our Open source
                              organisation and Open Source Protocol.
                            </p>
                            <a
                              href="https://github.com/decosystem"
                              alt="decosystem"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Contribute
                              <FaChevronRight
                                className="ms-2"
                                style={{ marginBottom: '2px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="banner-section-3 py-5">
          <div className="container">
            <p className="banner-text-2 mb-5">Top Scaling Tech solution</p>
            <div class="row">
              <div class="col">
                <ul class="nav nav-pills mb-4 justify-content-center" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-pos-tab" data-bs-toggle="pill" data-bs-target="#pills-pos" type="button" role="tab" aria-controls="pills-pos" aria-selected="true">Main Net</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-testnet-explorer-tab" data-bs-toggle="pill" data-bs-target="#pills-testnet-explorer" type="button" role="tab" aria-controls="pills-testnet-explorer" aria-selected="false">Test Net</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-validate-node-tab" data-bs-toggle="pill" data-bs-target="#pills-validate-node" type="button" role="tab" aria-controls="pills-validate-node" aria-selected="false">Validator</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-pos" role="tabpanel" aria-labelledby="pills-pos-tab">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-6 text-center ">
                        <img className="" src={gif2} style={{ width: "50%" }} alt="gif" />
                      </div>
                      <div className="col-lg-6">
                        <h3 className="banner-text-4">Main Net</h3>
                        <p className="banner-text-5">The live and operational version of the D-Chain blockchain. It is the main network that users and developers interact with to conduct transactions and build decentralized applications (DApps).</p>
                        <a href="https://mainnet-explorer.d-ecosystem.io/" target="_blank" className="banner-button-2" type="submit">Explore with Explorer <BsArrowUpRight className="banner-button-icon-1" /></a>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-testnet-explorer" role="tabpanel" aria-labelledby="pills-testnet-explorer-tab">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-6 text-center ">
                        <img className="" src={gif3} style={{ width: "50%" }} alt="gif" />
                      </div>
                      <div className="col-lg-6">
                        <h3 className="banner-text-4">Test Net</h3>
                        <p className="banner-text-5">D-Chain can be used by developers to test smart contracts, decentralized applications, and other blockchain-related services. It can also be used by users to experiment with different features and to learn more about how the blockchain works.</p>
                        <a href="https://testnet-explorer.d-ecosystem.io/" target="_blank" className="banner-button-2" type="submit">Explore with Explorer <BsArrowUpRight className="banner-button-icon-1" /></a>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-validate-node" role="tabpanel" aria-labelledby="pills-validate-node-tab">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-6 text-center ">
                        <img className="" src={gif4} style={{ width: "50%" }} alt="gif" />
                      </div>
                      <div className="col-lg-6">
                        <h3 className="banner-text-4">Validator</h3>
                        <p className="banner-text-5">validator is a participant in the network who is responsible for verifying and validating transactions and adding them to the blockchain. Validators play a critical role in ensuring the security, transparency, and integrity of the blockchain network.</p>
                        <a href="/comingsoon" className="banner-button-2" type="submit">Become Validator <BsArrowUpRight className="banner-button-icon-1" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="banner-section-4-4 py-5">
          <div className="container">
            <p className="banner-text-2 mb-5">Top Scaling Tech solution</p>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <Hometabs />
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section-4 py-5">
          <div className="container">
            <p className="banner-text-2 mb-5">KYC Verified</p>
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <a href="https://www.fairyproof.com/doc/D-Chain-Audit-Report-050423.pdf" target="_blank">
                  <img src={kycdeco} alt="kycdeco" />
                </a>
              </div>
              <div class="col-lg-5">
                <a href="https://www.coinscope.co/coin/dcx/kyc" target="_blank">
                  <img src={kycdeco1} alt="kycdeco" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section-4 py-5">
          <div className="container">
            <p className="banner-text-2 mb-2">Roadmap</p>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <Roadmap />
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section-5 py-5">
          <div className="container">
            <p className="banner-text-2 mb-2">Coinomics</p>
            <div class="row justify-content-center">
              <div class="col-lg-11">
                <Coinomics />
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section-5 py-5">
          <div className="container">
            <p className="banner-text-2 mb-5">Coin Allocation & Vesting</p>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <img
                  className="d-lg-block d-none"
                  src={coinomicsdesktop}
                  alt="coinomics"
                />
                <img
                  className="d-lg-none d-block"
                  src={coinomicsmobile}
                  alt="coinomics"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="banner-section-66 py-5 overflow-hidden">
          <div className="container">
            <p className="banner-text-2 mb-5">Expecting to become The largest <br /> and most thriving ecosystem</p>
            <div class="row row-cols-1 row-cols-md-3 g-4 col-lg-11 mx-auto position-relative justify-content-center">
              <div class="col">
                <div className="text-center">
                  <img src="https://aura.network/static/media/aura.723350ceb285789ebe35b1f5d321b2c8.svg" alt="logo"></img>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={29123} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>+</h5>
                    <p class="banner-section-6-text-2">Total Contract Creators</p>
                  </div>
                </div>
              </div>
              <div class="col">
              <div className="text-center">
                  <img src="https://aura.network/static/media/aura.723350ceb285789ebe35b1f5d321b2c8.svg" alt="logo"></img>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={223.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      M+</h5>
                    <p class="banner-section-6-text-2">Unique Addresses</p>
                  </div>
                </div>
              </div>
              <div class="col">
              <div className="text-center">
                  <img src="https://aura.network/static/media/aura.723350ceb285789ebe35b1f5d321b2c8.svg" alt="logo"></img>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={2.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      B+</h5>
                    <p class="banner-section-6-text-2">Transactions</p>
                  </div>
                </div>
              </div>
              <div class="col">
              <div className="text-center">
                  <img src="https://aura.network/static/media/aura.723350ceb285789ebe35b1f5d321b2c8.svg" alt="logo"></img>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={291152} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +</h5>
                    <p class="banner-section-6-text-2">NFT Sales Volume</p>
                  </div>
                </div>
              </div>
              <div class="col">
              <div className="text-center">
                  <img src="https://aura.network/static/media/aura.723350ceb285789ebe35b1f5d321b2c8.svg" alt="logo"></img>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={223.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      M+</h5>
                    <p class="banner-section-6-text-2">Deployed Smart Contracts</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="banner-section-6 py-5 overflow-hidden">
          <div className="container">
            <p className="banner-text-2 mb-5">Expecting to become The largest <br /> and most thriving ecosystem</p>
            <div class="row row-cols-1 row-cols-md-3 g-4 col-lg-11 mx-auto position-relative">
              <img src={glow2} alt="glow" className="banner-section-6-glow-1" />
              <img src={glow2} alt="glow" className="banner-section-6-glow-2" />

              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={29123} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>+</h5>
                    <p class="banner-section-6-text-2">Total Contract Creators</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card banner-section-6-middle-card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={223.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      M+</h5>
                    <p class="banner-section-6-text-2">Unique Addresses</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={2.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      B+</h5>
                    <p class="banner-section-6-text-2">Transactions</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={291152} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +</h5>
                    <p class="banner-section-6-text-2">NFT Sales Volume</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card banner-section-6-middle-card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={223.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      M+</h5>
                    <p class="banner-section-6-text-2">Deployed Smart Contracts</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="banner-section-6-text-1">
                      <CountUp start={this.state.focus ? 0 : null} end={2.52} duration={3} redraw={false}>
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={(isVisible) => {
                            if (isVisible) { this.setState({ focus: true }); }
                          }}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      B+</h5>
                    <p class="banner-section-6-text-2">Transactions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="py-5 Partner-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5">
              Get DCX Coin With Our Partner
            </h1>

            <div class="row row-cols-2 row-cols-lg-4 g-4 col-lg-10 mx-auto">
              <div class="col">
                <div class="card">
                  <a href="https://www.onramper.com/" target="_blank">
                    <img src={onramper} alt="onramper" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://transak.com/" target="_blank">
                    <img src={transak} alt="transak" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://www.sendwyre.com/" target="_blank">
                    <img src={Wyre} alt="Wyre" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://xanpool.com/" target="_blank">
                    <img src={xanpool} alt="xanpool" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a
                    href="https://www.livecoinwatch.com/price/DEcosystem-_DCX"
                    target="_blank"
                  >
                    <img
                      src={coinwatch}
                      className="coinwatch-listing-logo"
                      alt="coinwatch"
                    />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://coinsniper.net/coin/46018" target="_blank">
                    <img
                      src={sniper}
                      className="sniper-listing-logo"
                      alt="sniper"
                    />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a
                    href="https://coincodex.com/ido/d-ecosystem/"
                    target="_blank"
                  >
                    <img src={codex} alt="codex" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="https://www.cyberscope.io/" target="_blank">
                    <img src={cyberscope} alt="cyberscope" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="row">
              <div className="col-lg-12 p-0">
                <Marquee loop={0} pauseOnHover={true} speed={100} className="partners-marquee-container">
                  <a href="https://www.livecoinwatch.com/price/DEcosystem-_DCX" target="_blank">
                    <img src={coinwatch} alt="coinwatch" />
                  </a>
                  <a href="https://coinsniper.net/coin/46018" target="_blank">
                    <img src={sniper} alt="sniper" />
                  </a>
                  <a href="https://coincodex.com/ido/d-ecosystem/" target="_blank">
                    <img src={codex} alt="codex" />
                  </a>
                  <a href="" target="_blank">
                    <img src={cyberscope} alt="cyberscope" />
                  </a>
                  <a href="" target="_blank">
                    <img src={onramper} alt="onramper" />
                  </a>
                  <a href="" target="_blank">
                    <img src={transak} alt="transak" />
                  </a>
                  <a href="" target="_blank">
                    <img src={Wyre} alt="Wyre" />
                  </a>
                  <a href="" target="_blank">
                    <img src={xanpool} alt="xanpool" />
                  </a>
                </Marquee>

              </div>
            </div> */}
          </div>
        </section>

        <section className="py-5 made-by-decosystem-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5">Made by D-Ecosystem</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
              <div class="col">
                <a href="http://d-wallet.io/" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dwalletmade} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">D-Wallet</p>
                        <p className="made-by-text-2">
                          Ultimate solution for crypto enthusiasts to secure
                          their digital assets.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="https://defi.d-ecosystem.io/" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={defi} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">DEFI</p>
                        <p className="made-by-text-2">
                          Buy and Sell Instantly on
                          D-Swap
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="https://defi.d-ecosystem.io/swap" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dswapmade} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">D-Swap</p>
                        <p className="made-by-text-2">
                          Swap your tokens from anywhere, anytime with your
                          wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dnftmade} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">D-NFT</p>
                        <p className="made-by-text-2">
                          Discover a new dimension of digital ownership with
                          D-NFT.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a
                  href="https://mainnet-explorer.d-ecosystem.io/"
                  target="_blank"
                >
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dechainmade} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">D-Chain</p>
                        <p className="made-by-text-2">
                          Ultimate blockchain solution to secure your data,
                          simplifies your life.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dexchangemade} alt="kreft" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">D-Exchange</p>
                        <p className="made-by-text-2">
                          Start using the fast and secure platform to buy, sell,
                          trade & earn cryptocurrency.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={dusd} alt="dusd" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">DUSD</p>
                        <p className="made-by-text-2">Coming Soon</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card">
                    <div className="row">
                      <div className="col-lg-4 col-3">
                        <img src={validator} alt="validator" />
                      </div>
                      <div className="col-lg-8 col-9">
                        <p className="made-by-text-1">Validator Pool</p>
                        <p className="made-by-text-2">Coming Soon</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 Partneradvisor-listing-banner-section">
          <div className="container">
            <h1 className="banner-text-2 mb-5"> D-Ecosystem Advisors</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
              <div class="col">
                <div class="card">
                  <a href="https://www.kreft-eger.de/" target="_blank">
                    <img src={kreft} alt="kreft" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <a href="" target="_blank">
                    <img src={Srt} alt="codex" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card py-5">
                  <a href="https://eazzy.global/" target="_blank">
                    <img src={eg} alt="codex" />
                  </a>
                </div>
              </div>
              {/* <div class="col">
                <div class="card py-5">
                  <a href="https://www.daostarter.pro/#/DAOStarter/index" target="_blank">
                    <img className='backers-image-sizing-css' src={DAOStarter} alt="codex" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card py-5">
                  <a href="https://kingdomstarter.io/" target="_blank">
                    <img src={KDG} alt="codex" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card py-5">
                  <a href="https://gagarin.world/" target="_blank">
                    <img src={Gagarin} alt="codex" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="card py-5">
                  <a href="https://daolaunch.net/" target="_blank">
                    <img src={DAOLaunch} alt="codex" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>

{/* 
        <section className="py-5 made-by-decosystem-banner-section ">
          <div className="container">
            <h1 className="banner-text-2 mb-5">Partners</h1>

            <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
              <div class="col">
                <div class="card">
                    <img style={{width:"50%"}} className='mx-auto' src={Tinqfinance} alt="Tinqfinance" />
                </div>
              </div>
              <div class="col">
                <div class="card">
                    <img style={{width:"50%"}} className='mx-auto' src={EonianFinance} alt="EonianFinance" />
                </div>
              </div>

            </div>
          </div>
        </section> */}

        <section className="py-5">
          <div className="container overflow-hidden">
            <h1 className="banner-text-2 mb-5">Compatible Networks</h1>

            <div class="row">
              <div class="col">
                <ul id="categories" class="clr">
                  <li className="ethereum-anchor-link">
                    <div>
                      <img src={ethereum} alt="ethereum" />
                    </div>
                  </li>
                  <li className="fantom-anchor-link">
                    <div>
                      <img src={fantom} alt="fantom" />
                    </div>
                  </li>
                  <li className="avalanche-anchor-link">
                    <div>
                      <img src={avalanche} alt="avalanche" />
                    </div>
                  </li>
                  <li className="binancesmartchain-anchor-link">
                    <div>
                      <img src={binancesmartchain} alt="binancesmartchain" />
                    </div>
                  </li>
                  <li className="polygon-anchor-link">
                    <div>
                      <img src={polygon} alt="polygon" />
                    </div>
                  </li>
                  <li className="arbutrum-anchor-link">
                    <div>
                      <img src={arbutrum} alt="arbutrum" />
                    </div>
                  </li>
                  <li className="optimism-anchor-link">
                    <div>
                      <img src={optimism} alt="optimism" />
                    </div>
                  </li>
                  <li className="celo-anchor-link">
                    <div>
                      <img src={celo} alt="celo" />
                    </div>
                  </li>
                  <li className="moonbeam-anchor-link">
                    <div>
                      <img src={moonbeam} alt="moonbeam" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 projects-build-starting-section">
          <div className="container">
            <p className="banner-text-2 mb-1">
              D-Chain Incubation
            </p>

            <div class="row row-cols-2 row-cols-lg-5 g-4 mt-2 justify-content-center " style={{width:"fit-content"}}>
              {/* <div class="col">
                  <div class="card-box">
                    <img src={mixedrarity} alt="mixedrarity" />
                  </div>
              </div>
              <div class="col">
                  <div class="card-box">
                    <img src={Tyrion} alt="Tyrion" />
                  </div>
              </div>
              <div class="col">
                  <div class="card-box">
                    <img src={toptoken} alt="toptoken" />
                  </div>
              </div> */}
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card-box">
                    <img src={easymade} alt="kreft" />
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/comingsoon" target="_blank">
                  <div class="card-box border-right-0">
                    <img src={minimade} alt="kreft" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 featured-by-section">
          <div className="container">
            <span className="mb-5">FEATURED BY</span>
            <div class="row row-cols-2 row-cols-lg-4 g-4 mt-2">
              <div class="col">
                <div class="">
                  <a
                    href="https://www.benzinga.com/pressreleases/23/03/31577181/open-source-blockchain-d-ecosystem-raises-6m-ido-on-march-29th"
                    target="_blank"
                  >
                    <img src={Benzinga} alt="Benzinga" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="">
                  <a
                    href="https://www.marketwatch.com/press-release/open-source-blockchain-d-ecosystem-raises-6m-ido-on-march-29th-2023-03-27?mod=search_headline"
                    target="_blank"
                  >
                    <img src={Marketwatch} alt="Marketwatch" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="">
                  <a
                    href="https://dailyhodl.com/2023/03/27/open-source-blockchain-d-ecosystem-raises-6-million-ahead-of-ido-on-march-29-2023/"
                    target="_blank"
                  >
                    <img src={dailyHodl} alt="dailyHodl" />
                  </a>
                </div>
              </div>
              <div class="col">
                <div class="">
                  <a
                    href="https://www.digitaljournal.com/pr/news/open-source-blockchain-d-ecosystem-raises-6m-ido-on-march-29th"
                    target="_blank"
                  >
                    <img src={digitaljournal} alt="digitaljournal" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section-7 py-5">
          <Blog />
        </section>

        {/* <section className="banner-section-8 py-5 position-relative overflow-hidden">
          <div className="container-fluid">
            <video className='banner-video-bottom-1' autoPlay loop muted>
              <source src={box} type="video/mp4" />
            </video>
            <div className="container">
              <div class="row">
                <div class="col-lg-5">
                  <p className="stay-mail-text-1">STAY UP TO DATE</p>
                  <p className="stay-mail-text-2">Get our newsletter</p>
                  <form onSubmit={this.handleSubmit}>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your mail"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <span className="input-group-text" id="basic-addon1"><BsArrowRight type="submit" /></span>
                    </div>
                    <span className="" style={{ color: "red" }}>{this.state.emailError}</span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="banner-section-8 py-5 position-relative overflow-hidden">
          <div className="container-fluid">
            <video className="banner-video-bottom-1" autoPlay loop muted>
              <source src={box} type="video/mp4" />
            </video>
            <div className="container">
              <div class="row">
                <div class="col-lg-7">
                  <p className="product-stay-mail stay-mail-text-2">
                    Get Early Access to D-Ecosystem Products & Features
                  </p>
                  <form onSubmit={this.handleSubmit} autoComplete='off'>
                    <div className="deco d-flex flex-row align-items-center justify-content-center">
                      <div class="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please enter your E-mail address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='mx-3'>
                        <button type="submit" className="wait-button">
                          Join the Waitlist
                        </button>
                      </div>
                    </div>
                    <span className="" style={{ color: 'red' }}>
                      {this.state.emailError}
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default App
