import React from "react";
import Bubble1 from "../Teamcrew/Bubble1"
import Bubble2 from "../Teamcrew/Bubble2"
import Bubble3 from "../Teamcrew/Bubble3"
import Bubble1mobile from "../Teamcrew/Bubble1-mobile"
import Bubble2mobile from "../Teamcrew/Bubble2-mobile"
import Bubble3mobile from "../Teamcrew/Bubble3-mobile"




function Bubble() {




    return (
        <div className="container-fluid banner-section-3 internal-page-padding-top">
            <p className="banner-text-2 mb-5">Meet the Crew</p>
            <div className="row">
                <div className="col">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Our Core Team</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">DAO Founders</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Other Teams</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="d-lg-block d-none">
                                <Bubble1 />
                            </div>
                            <div className="d-lg-none d-block">
                                <Bubble1mobile />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="d-lg-block d-none">
                                <Bubble2 />
                            </div>
                            <div className="d-lg-none d-block">
                                <Bubble2mobile />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="d-lg-block d-none">
                                <Bubble3 />
                            </div>
                            <div className="d-lg-none d-block">
                                <Bubble3mobile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bubble;
