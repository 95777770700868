

import box from "../../Assests/images/banner/box.mp4"
import PhoneInput from 'react-phone-number-input';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';


function Contactus() {

    const [phone, setPhone] = useState()

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        if (data)
            data["phone"] = phone
    }

    return (
        <div className="App">

            <section className=" internal-page-padding-top pb-5">

                <section className="banner-section-8 py-5 position-relative overflow-hidden">
                    <div className="container-fluid">
                        <video className='banner-video-bottom-1' autoPlay loop muted>
                            <source src={box} type="video/mp4" />
                        </video>
                        <div className="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    <p className="stay-mail-text-2">Get in touch with us</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <p className="mb-2 contact-text-1">Name</p>
                                        <div class="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your Name"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                name="email"
                                                id="name"
                                                {...register("name", { required: true })}
                                            />
                                        </div>
                                        {errors.name && <span className="text-danger">Name is required</span>}
                                        <p className="mb-2 contact-text-1">Mail</p>
                                        <div class="input-group mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter your Mail"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                name="email"
                                                id="email"
                                                {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                                            />
                                        </div>
                                        {errors.email && errors.email.type === "required" && (
                                            <span className="text-danger" >Email is required</span>
                                        )}
                                        {errors.email && errors.email.type === "pattern" && (
                                            <span className="text-danger" >Invalid email address</span>
                                        )}
                                        <p className="mb-2 contact-text-1">PhoneNumber</p>
                                        <div class="input-group mb-3">
                                            <PhoneInput
                                                //style={...}
                                                defaultCountry="US"
                                                value={phone}
                                                onChange={setPhone}
                                            />
                                        </div>
                                        <p className="mb-2 contact-text-1">Type Something</p>

                                        <div class="mb-3 input-group">
                                            <textarea class="form-control" placeholder="Type Something" rows="3"
                                                id="message"
                                                {...register("message", { required: true })}
                                            ></textarea>
                                        </div>
                                        {errors.message && <span className="text-danger" >Message is required</span>}<br/>

                                        <button type="submit" class="banner-button-1">Submit
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="banner-button-icon-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"></path></svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>





        </div>
    );
}


export default Contactus;
