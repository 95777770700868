import React from "react";


import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";
// import required modules
import { Navigation } from "swiper";
import { BsArrowUpRightCircle } from 'react-icons/bs';
import axios from "axios";
import { API_URL } from "../config/config";
import moment from "moment";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.myDivToFocus = React.createRef()
    this.state = {
      eventsData: [],
      pastData: [],
      presentData: [],
      futureData: [],
      errors: {},
    };
  }
  getEventsData = () => {
    axios({
      method: "GET",
      url: `${API_URL}events/data`
    }).then((data) => {
      var collectData = data.data
      if (collectData) {
        var todayDate = new Date();
        collectData.map((ele) => {
          var momentString = moment(todayDate).utc().format('YYYY-MM-DD');
          var eventDate = moment(ele.events_time).utc().format('YYYY-MM-DD');
          if (momentString > eventDate) {
            this.setState({ pastData: [ele] })

          } else if (momentString == eventDate) {
            this.setState({ presentData: [ele] })
          } else if (momentString < eventDate) {
            this.setState(prevState => ({
              futureData: [...prevState.futureData, ele]
            }));
          }
        })
      }
      this.setState({ eventsData: data.data });
    }).catch((err) => console.log(err));
  };

  componentDidMount = () => {
    this.getEventsData();
  }

  render() {

    return (
      <div className="App">


        <section className=" internal-page-padding-top pb-5">
          <div className="container">
            <p className="banner-text-2">Event</p>
            <div className="row">
              <div className="col">
                <div className="event-banner-section">
                  <ul class="nav nav-pills mb-3 justify-content-center " id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="pills-past-tab" data-bs-toggle="pill" data-bs-target="#pills-past" type="button" role="tab" aria-controls="pills-past" aria-selected="true">past Event</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-present-tab" data-bs-toggle="pill" data-bs-target="#pills-present" type="button" role="tab" aria-controls="pills-present" aria-selected="false">present Event</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-future-tab" data-bs-toggle="pill" data-bs-target="#pills-future" type="button" role="tab" aria-controls="pills-future" aria-selected="false">future Event</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-past" role="tabpanel" aria-labelledby="pills-past-tab">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper "
                        spaceBetween={80}
                        breakpoints={{
                          "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@0.75": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.50": {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                        }}
                      >
                        {
                          this.state.pastData.map((event) => {
                            return (
                              <SwiperSlide >
                                <div class="card h-100">
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Title :</p>
                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_time} </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Description :</p>
                                      </div>
                                      <div className="col-6">
                                        <p>{event.events_description}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event time :</p>

                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_time} </p>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-end mt-3">
                                      <button type="button" class=" join-event-button">Join Event </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                      {/* <div class="col">
                        <p className="text-center mt-5">NIL</p>
                      </div> */}
                    </div>
                    <div class="tab-pane fade" id="pills-present" role="tabpanel" aria-labelledby="pills-present-tab">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper "
                        spaceBetween={80}
                        breakpoints={{
                          "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@0.75": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.50": {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                        }}
                      >
                        {
                          this.state.presentData.map((event) => {
                            return (
                              <SwiperSlide >
                                <div class="card h-100">
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Title :</p>
                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_title} </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Description :</p>
                                      </div>
                                      <div className="col-6">
                                        <p>{event.events_description}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event time :</p>
                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_time} </p>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-end mt-3">
                                      <button type="button" class=" join-event-button">Join Event </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }

                      </Swiper>
                    </div>
                    <div class="tab-pane fade" id="pills-future" role="tabpanel" aria-labelledby="pills-future-tab">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper "
                        spaceBetween={80}
                        breakpoints={{
                          "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@0.75": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@1.50": {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                        }}
                      >
                        {
                          this.state.futureData.map((event) => {
                            return (
                              <SwiperSlide >
                                <div class="card h-100">
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Title :</p>
                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_title} </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event Description :</p>
                                      </div>
                                      <div className="col-6">
                                        <p>{event.events_description}</p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-6">
                                        <p>Event time :</p>
                                      </div>
                                      <div className="col-6">
                                        <p> {event.events_time} </p>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-end mt-3">
                                      <button type="button" class=" join-event-button">Join Event </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }

                      </Swiper>

                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper "
                        spaceBetween={80}
                        breakpoints={{
                          "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          "@0.75": {
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          "@1.00": {
                            slidesPerView: 2,
                            spaceBetween: 40,
                          },
                          "@1.50": {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                        }}
                      >

                        <SwiperSlide >
                          <div class="card h-100">
                            <div class="card-body">
                              <div className="d-flex flex-row">
                                <div>
                                  <p>Event Title :</p>
                                  <p>Event <br />Description :</p>
                                  <p>Event time :</p>

                                </div>
                                <div className="ms-auto text-end">
                                  <p>D-Coin Initial DEX offering</p>
                                  <p> Join Coin sales First phase.  <br /> will open the Web IDO Soon.</p>
                                  <p>8.00 AM UTC 29.03.2023</p>
                                </div>
                              </div>
                              <div className="d-flex flex-row justify-content-end mt-3">
                                <button type="button" class=" join-event-button">Join Event </button>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>

                      </Swiper> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default App;
