import React from "react";
import Error from "../Assests/images/404.png"
import Errorbutton from "../Assests/images/Error.png"
import { CiViewList } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { CiGlobe } from "react-icons/ci";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import Background from "../../src/Assests/images/bg.png"


class Error404 extends React.Component {
  constructor(props) {
    super(props);

  }
  render() {
    return (
      <div className="App">



        <section className="new-hackathon-banner-section">
          <div className="container">
            <div className="row min-vh-100 justify-content-center align-items-center">
              <div className="col text-center">
                <h2 style={{ letterSpacing: "1px" }}><span className="fw-bold">D-Chain</span> presents</h2>
                <h1 className="hackathon-text-1">VISIONARY</h1>
                <h2 className="hackathon-text-2">Unleash Your Vision, Revolutionize Blockchain – Join the
                  VISIONARY Hackathon 2024</h2>
                <a className="new-hackathon-button-1" href="/hackathon-register">Register Here</a>
              </div>
            </div>
          </div>
        </section>

        <section className="new-hackathon-banner-section-2 py-5">
          <div className="container">
            <h3 className="hackathon-text-3 text-center mb-lg-5 mb-3">Discover the Benefits of Building with D-Chain</h3>
            <div class="row row-cols-1 row-cols-lg-3 row-cols-md-3 g-4">
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Zero Gas Fees</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Eco-friendly Consensus</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Versatile Applications</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Robust Security</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Scalability and Performance</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <h5 class="card-title">Diverse Applications</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="new-hackathon-banner-section-2 py-5">
          <div className="container">
            <h3 className="hackathon-text-3 text-center mb-lg-5 mb-3">Discover the Benefits of Building with D-Chain</h3>
            <div class="row g-4">
              <div class="col-lg-4">
                <h3 className="hackathon-text-4 mb-4">Event Details</h3>
                <p className="hackathon-text-5 mb-1" style={{ color: "#85b6ff" }}><CiViewList className="me-2" />Register Date: 03/05-04/05, 2024</p>
                <p className="hackathon-text-5 mb-1" style={{ color: "#85b6ff" }}><CiCalendar className="me-2" />Hackathon Date: April 22, 2024</p>
                <p className="hackathon-text-5 mb-1" style={{ color: "#85b6ff" }}><IoTimeOutline className="me-2" />All Day</p>
                <p className="hackathon-text-5 mb-5" style={{ color: "#85b6ff" }}><IoLocationOutline className="me-2" />Online</p>
                <a className="new-hackathon-button-1" href="/hackathon-register">Register Here</a>
                <div className="mt-5 d-flex gap-2">
                  <a href="https://www.d-ecosystem.io/" target="_blank"><CiGlobe className="hackathon-text-3" /></a>
                  <a href="https://t.me/d_ecosystem_group " target="_blank"><FaTelegramPlane className="hackathon-text-3" /></a>
                  <a href="https://twitter.com/d_ecosystem" target="_blank"><FaTwitter className="hackathon-text-3" /></a>
                  <a href="https://discord.gg/ZXqEtGc3Vy" target="_blank"><FaDiscord className="hackathon-text-3" /></a>
                </div>
              </div>
              <div class="col-lg-8">
                <p className="hackathon-text-5">D-Ecosystem is proud to announce the VISIONARY Hackathon 2024, with a
                  prize pool of up to $50,000! This pioneering event is open to individuals and
                  teams who are ready to shape the future of blockchain technology. </p>
                <p className="hackathon-text-5">The hackathon is organized into distinct categories, each designed to push the
                  boundaries of blockchain innovation:</p>
                <p className="hackathon-text-5">1. Ecosystem Support: Develop analytics tools, and sites.</p>
                <p className="hackathon-text-5">2. NFT Innovation: Create groundbreaking NFT collections, including
                  experimental elements.</p>
                <p className="hackathon-text-5">3. DeFi Development: Build original applications leveraging D-Chain’s robust
                  infrastructure.</p>
                <p className="hackathon-text-5">4. New Frontiers: Explore uncharted territories with DAOs´ decentralized
                  internet projects´ and more.</p>
                <p className="hackathon-text-5">5. Zero-Knowledge Advancements: Contribute to the future of scaling and
                  privacy with advanced contracts.</p>
                <p className="hackathon-text-5 mb-5">6. Gaming on D-Chain: Design games that are entirely on-chain or utilize the
                  D-Chain for unprecedented experiences.</p>
                <p className="hackathon-text-5 mb-5">As a special incentive, D-Ecosystem has dedicated a $5,000 USDC bounty,
                  encouraging developers to innovate within these categories on the D-Chain.</p>
                <h5 className="fw-bold hackathon-text-5">Key Details:</h5>
                <ul className="hackathon-text-5">
                  <li>Hackathon Start Date: January 15, 2024</li>
                  <li>Registration Deadline: February 10, 2024, 11:59 PM UTC</li>
                  <li>Event Duration: 1 Day of Intense Development and Innovation (Virtual)</li>
                </ul>
                <h5 className="fw-bold hackathon-text-5 mt-5">Why Participate ?</h5>
                <ul className="hackathon-text-5">
                  <li>Showcase Your Skills: A platform to demonstrate your talents and innovative
                    solutions in the blockchain realm.</li>
                  <li>Win Prizes: A significant prize pool, with the opportunity to win cash and in-
                    kind rewards.</li>
                  <li>Network and Collaborate: Engage with other talented developers and the
                    D-Ecosystem community</li>
                  <li>Receive Support and Recognition: Winning projects receive funding,
                    technical support, and integration into the D-Ecosystem.</li>

                </ul>
                <h5 className="fw-bold hackathon-text-5 mt-5">How to Register:</h5>
                <p className="hackathon-text-5">Don't miss this chance to be a part of the blockchain revolution! Registrations
                  are now open. Visit our official hackathon page for more details and to sign up:
                  VISIONARY Hackathon 2024 Registration
                </p>
                <p className="hackathon-text-5">Build the Future on D-Chain - Your Ideas, Our Support. Let's Innovate Together!</p>
              </div>

            </div>
          </div>
        </section>



      </div>
    );
  }
}

export default Error404;
