import React, { useEffect, useState } from 'react';
import { useReactCountdown } from 'use-react-countdown';
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
import Marquee from "react-fast-marquee";
import axios from 'axios';
import { API_URL } from '../../config/config';
import { data } from 'jquery';


export default function Buggy() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'March 29, 2023 03:07:00'
    );
    const [announcements, setAnnouncements] = useState([]);
    useEffect(() => {
        axios({
            method: 'GET',
            url: `${API_URL}announcements/data`
        }).then((data) => {
            setAnnouncements(data.data)
        })
    }, []);
    return (
        <div>
            {/* <Marquee loop={0} pauseOnHover={true} speed={100}>
                <span className='marquee-text-1'><a href="https://launchpad.d-ecosystem.io/" target="_blank" alt="decosystem" rel="noopener noreferrer">D-Coin (DCX) Coin sales on 29th March 2023 on IDO Launchpad </a></span><BsArrowUpRightCircleFill className='marquee-icon-1' />
                <span className='marquee-text-1'><a href="https://d-ecosystem.io/" target="_blank" alt="decosystem" rel="noopener noreferrer">Join the D-Ecosystem Airdrop and Potentially Win Over $100 in DCX Coins </a><BsArrowUpRightCircleFill className='marquee-icon-1' /></span>
                <span className='marquee-text-1'><a href="https://airdrop.d-ecosystem.io/" target="_blank" alt="decosystem" rel="noopener noreferrer">Earn Commissions with the D-Ecosystem Referral Program -2 DCX per Referral  </a><BsArrowUpRightCircleFill className='marquee-icon-1' /></span>
                <span className='marquee-text-1'><a href="https://medium.com/@decosystem.io/join-the-d-ecosystem-ambassador-program-shape-the-future-of-blockchain-technology-and-earn-2b8b09ceeed" target="_blank" alt="decosystem" rel="noopener noreferrer">Join D-Ecosystem Ambassador Program</a>  <BsArrowUpRightCircleFill className='marquee-icon-1' /></span>
                <span className='marquee-text-1'><a href="https://t.me/d_ecosystem_group" target="_blank" alt="decosystem" rel="noopener noreferrer">Join Telegram</a>  <BsArrowUpRightCircleFill className='marquee-icon-1' /></span>
                <span className='marquee-text-1'><a href="https://discord.com/invite/TacRQNaVdH" alt="decosystem" target="_blank" rel="noopener noreferrer">Join Discord</a>  <BsArrowUpRightCircleFill className='marquee-icon-1' /></span>
                <span className='marquee-text-1'><a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem">Follow Twitter</a>  <BsArrowUpRightCircleFill className='marquee-icon-1' /></span>

            </Marquee> */}

            <div className="d-lg-block d-none">
                <Marquee loop={0} pauseOnHover={true} speed={100} className='top-navbar-marquee-container' >
                    {
                        announcements.map((announce) => {
                            return (
                                <span className='marquee-text-1'>
                                    <a href={announce.announcementlink} target="_blank" rel="noopener noreferrer" alt="decosystem">
                                        {announce.announcementname}
                                        <BsArrowUpRightCircleFill className='marquee-icon-1' />

                                    </a>
                                </span>
                            )
                        })}
                </Marquee>
            </div>
            <div className="d-lg-none d-block">
                <Marquee loop={0} pauseOnHover={true} className='top-navbar-marquee-container' >
                    {
                        announcements.map((announce) => {
                            return (
                                <span className='marquee-text-1'>
                                    <a href={announce.announcementlink} target="_blank" rel="noopener noreferrer" alt="decosystem">
                                        {announce.announcementname}
                                        <BsArrowUpRightCircleFill className='marquee-icon-1' />

                                    </a>
                                </span>
                            )
                        })}
                </Marquee>
            </div>
        </div>
    );
}